import React from 'react';
import * as sui from 'semantic-ui-react';

// addons
import windowSize from 'react-window-size';
//import hookcord from 'hookcord';

// elements
import {MenuBarMargin} from '../elements/menuBar.js';
import Colors from '../constants/colors';
import SlimUI from '../elements/slimUI';
import CustomForm from '../elements/customForm';
import { Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

import Network from '../constants/network.js';

class KontaktPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  async sendMessage(data) {
    /*
    // DISCORD
    const hook = new hookcord.Hook();
    hook.login('718499585315700806', process.env.REACT_APP_WEBHOOK_SECRET);
    hook.setPayload({
      'embeds': [{
        'title': data.subject,
        'color': 4093951,
        'author': {
          'name': data.name,
          'icon_url': 'https://cdn.discordapp.com/embed/avatars/0.png',
        },
        'fields': [{
            'name': 'E-Mail:',
            'value': '``' + data.mail + '``',
          }, {
            'name': 'Nachricht:',
            'value': '```' + data.body + '```',
          },
        ],
      }],
    });
    return hook.fire().then(() => {
      return true;
    }).catch(error => {
      console.error(error);
      return false;
    });
    */

    // BACKEND
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        name: data.name,
        mail: data.mail,
        subject: data.subject,
        message: data.body,
        attachments: data.attachments,
        copy: data.copy
      })
    };
    return fetch(Network.backendHost + 'contact', requestOptions).then(res => {
      return res.status === 200;
    }).catch(error => {
      console.error(error);
      return false;
    });
  }

  render() {
    // styles
    const mainStyle = {
      backgroundColor: Colors.background,
      minWidth: '100vw',
      minHeight: '100vh',
      paddingBottom: isMobile ? '100px' : '50px',
    };
    const bannerStyle = {
      backgroundColor: '#000000',
      color: Colors.aHover,
      fontSize: '20px',
      textAlign: 'center',
      padding: '20px',
      width: '100vw',
    };
    const btnStyle = {
      marginLeft: '3vw',
    };

    return (
      <div style={mainStyle}>
        <MenuBarMargin />
        {/* fix, because 'ziele' and 'projects' have a padding of 20px on root div */}
        <div style={{paddingTop: '20px'}} />
        <SlimUI>
          <CustomForm sendHandler={this.sendMessage} title='Kontakt' fields={[
            [
              {
                type: 'text',
                textType: 'name',
                key: 'name',
                text: <>Name</>,
                value: '',
                validation: true,
              },
              {
                type: 'text',
                textType: 'email',
                key: 'mail',
                text: <>E-Mail</>,
                value: '',
                validation: true,
              },
            ],
            [
              {
                type: 'text',
                key: 'subject',
                text: <>Betreff</>,
                value: '',
                validation: true,
              },
            ],
            [
              {
                type: 'textField',
                key: 'body',
                text: <>Nachricht</>,
                value: '',
                validation: true,
              },
            ],
            // TODO
            /*
            [
              {
                type: 'file',
                key: 'attachments',
                text: <>Dateien (optional)</>,
                value: '',
                multiple: true,
              },
            ],
            */
            [
              {
                type: 'checkbox',
                key: 'copy',
                text: <>Kopie der Nachricht als E-Mail senden</>,
                value: false,
                validation: false,
              },
            ],
            [
              {
                type: 'checkbox',
                key: 'privacy',
                text: <>Ich habe die <a target="_blank" rel="noopener noreferrer" href='/datenschutz'>Datenschutzerklärung</a> gelesen und akzeptiere, dass Daten gespeichert werden.</>,
                value: false,
                validation: 'Sie müssen die Datenschutzerkärung akzeptieren',
              },
            ],
            [
              {
                type: 'submit',
                text: <>Senden</>,
              },
            ],
          ]} />
        </SlimUI>
        <sui.Header style={bannerStyle}>
          Wenn du Teil des Fetzwerks werden willst, dann werde Mitglied im Verein.
          <Link to='/antragSelect'><sui.Button style={btnStyle} className='primaryColor'>Mitglied werden</sui.Button></Link>
        </sui.Header>
      </div>
    );
  }
}

export default windowSize(KontaktPage);
