import React from 'react';
import * as sui from 'semantic-ui-react';
import './projectItem.css';

// addons
import windowSize from 'react-window-size';

// elements
import WideUI from '../elements/wideUI';
import CustomSuiImg from '../elements/customSuiImg';
import { isMobile } from 'react-device-detect';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';

class ProjectItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      'accordionOpen': false
    };
    this.onAccordionClick = () => {
      this.setState({ 'accordionOpen': !this.state['accordionOpen'] });
    };
  }

  render() {
    const centerPlayer = this.props.windowWidth < 1100;

    const rootStyle = {
      backgroundImage: (this.state.accordionOpen ? 'linear-gradient(#000000E6, #000000C4, #000000E6)' : 'linear-gradient(#000000E0, #00000040, #000000E0)') + ', url("' + this.props.bg + '")',
      backgroundColor: '#000000',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      margin: '50px 0',
    };
    const titleStyle = {
      color: '#ffffff',
      letterSpacing: '2px',
    };
    const contentStyle = {
      minHeight: centerPlayer ? '0' : '500px', // prevents player overflowing
    };
    const playerContainerStyle = {
      marginLeft: centerPlayer ? (this.props.windowWidth > 767 ? 'calc(-35px - 7vw)' : '-50px') : '',
      width: centerPlayer ? '100vw' : '700px',
      height: centerPlayer ? 'calc(50vw + 69px)' : '450px',
      border: 'none',
      backgroundColor: '#00000000',
      padding: '0',
      display: this.props.content[0] === '' ? 'none' : '',
    };
    const galleryContentStyle = {
      margin: 'auto',
      width: centerPlayer ? '80vw' : '600px',
      height: centerPlayer ? '50vw' : '400px',
    };
    const secStyle = {
      color: '#b0b0b0',
    };

    const galleryContent = this.props.content.map(v => {
      if (v.indexOf("youtube") !== -1) {
        return (
          <iframe title='ytplayer' style={galleryContentStyle} src={v} frameborder="0" allow="fullscreen; accelerometer; encrypted-media; gyroscope; picture-in-picture" allowfullscreen />
        );
      }
      else {
        return (
          <CustomSuiImg style={galleryContentStyle} src={v} />
        );
      }
    });

    return (
      <div style={rootStyle}>
        <WideUI>
          <sui.Accordion>
            <sui.Accordion.Title active={this.state.accordionOpen} onClick={this.onAccordionClick}>
              <sui.Header style={titleStyle}>
                <sui.Icon name='dropdown' />
                {this.props.title}
              </sui.Header>
            </sui.Accordion.Title>
            <sui.Accordion.Content style={contentStyle} active={this.state.accordionOpen}>
              <sui.Segment floated={centerPlayer ? 'left' : 'right'} style={playerContainerStyle}>
                <Carousel children={galleryContent} showStatus={false} showIndicators={!isMobile} swipeable={galleryContent.length > 1} showThumbs={false} infiniteLoop={true} transitionTime={750} />
              </sui.Segment>
              <p>
                {this.props.desc}
              </p>
              <p style={secStyle}>
                Produktionszeitraum: {this.props.timespan}
                <br />
                <br />
                Produktion: {this.props.production}
                <br />
                Regie: {this.props.director}
                <br />
                Drehbuch: {this.props.script}
              </p>
            </sui.Accordion.Content>
          </sui.Accordion>
        </WideUI>
      </div>
    );
  }
}

export default windowSize(ProjectItem);
