import React from 'react';
import * as sui from 'semantic-ui-react';

class CustomSuiImg extends React.Component {
    constructor(props) {
        super(props);
        this.state = {loaded: false};
    }

    render() {
        return (
            <div>
                <sui.Dimmer active={!this.state.loaded}>
                    <sui.Loader />
                </sui.Dimmer>
                {React.cloneElement(<sui.Image />, {...this.props, onLoad: () => { this.setState({loaded: true})}})}
            </div>
        );
    }
}

export default CustomSuiImg;
