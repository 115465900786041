class FirebaseConstants {
    static config = {
        apiKey: 'AIzaSyBlgMqBHmoASTXV6FIzIPQIjfPVuTiLH8Q',
        authDomain: 'fetzwerk-debug.firebaseapp.com',
        databaseURL: 'https://fetzwerk-debug.firebaseio.com',
        storageBucket: 'fetzwerk-debug.appspot.com',
    };
}

export default FirebaseConstants;
