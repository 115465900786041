import React from 'react';
import ReactDOM from 'react-dom';
import * as sui from 'semantic-ui-react';

// addons
import windowSize from 'react-window-size';

// elements
import Checkbox from '../elements/checkbox.js';
import Colors from '../constants/colors';
import FormFullValidation from '../elements/formFullValidation';

class CustomForm extends React.Component {
    constructor(props) {
        super(props);

        this.allFields = []; // everything with a value
        for (var row of this.props.fields) {
            for (var col of row) {
                if ([
                    'text', 'file', 'textField', 'number', 'checkbox', 'radio', 'radio2'
                ].includes(col.type)) {
                    this.allFields.push(col);
                }
            }
        }

        var stateBuf = {};
        for (var f of this.allFields) {
            stateBuf[f.key] = '';
        }
        this.state = stateBuf;

        this.handleChange = (_, e) => {
            this.setState({ [e.name]: e.value }, _ => {
                this.preCheckInput();
                if (e.sechandler) {
                    e.sechandler(e.value, e.name);
                }
            });
        };

        this.handleUpload = e => {
            const fieldName = e.target.name;
            this.handleChange(undefined, {
                name: fieldName,
                value: e.target.value,
            });
            this.handleChange(undefined, {
                name: fieldName + '_data',
                value: [],
            });
            var totalSize = 0;
            var fileData = [];
            const files = e.target.files;
            const parent = ReactDOM.findDOMNode(e.target).parentNode;
            const ext = parent.getAttribute('filetypes') ? parent.getAttribute('filetypes').split(',') : [];
            for (var f of files) {
                if (ext.length > 0) {
                    var legal = false;
                    for (var x of ext) {
                        if (f.name.toLowerCase().endsWith(x)) {
                            legal = true;
                            break;
                        }
                    }
                    if (!legal) {
                        this.setState({
                            dimmerState: 3,
                            specialError: `Der Typ der Datei '${f.name}' ist nicht erlaubt. Folgende Formate werden akzeptiert: ${ext.join(', ')}`,
                        });
                        return;
                    }
                }
                totalSize += f.size;
            }
            if (totalSize > 10000000) {
                this.setState({
                    dimmerState: 3,
                    specialError: 'Die Dateien sind zu groß. Es können insgesamt 10MB hochgeladen werden.',
                });
                return;
            }
            for (var f of files) {
                const fr = new FileReader();
                const nameBuf = f.name;
                fr.onload = e2 => {
                    fileData.push({
                        file: nameBuf,
                        data: Buffer.from(e2.target.result).toString('base64'),
                    });
                    if (fileData.length === files.length) {
                        this.handleChange(undefined, {
                            name: fieldName + '_data',
                            value: fileData,
                        });
                    }
                };
                fr.readAsArrayBuffer(f);
            }
        };
    }

    setField(name, value) {
        this.setState({ [name]: value }, () => {
            this.preCheckInput();
        });
    }

    checkMail(email) {
        // https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript#comment17773334_46181
        return /[^@]+@[^@]+\.[^@]+/.test(email);
    }

    inputEmpty(value, textType) {
        if (typeof (value) === 'boolean') {
            return !value;
        }
        else if (typeof (value) === 'number') {
            return value === -1;
        }
        else {
            if (textType) {
                if (textType === 'email') {
                    return !this.checkMail(value);
                }
            }
            return value.trim() === '';
        }
    }

    // only removes warnings
    preCheckInput() {
        for (var f of this.allFields) {
            if (f.validation && !this.inputEmpty(this.state[f.key], f.textType)) {
                var buf = {};
                buf[f.key + 'empty'] = false;
                this.setState(buf);
            }
        }
    }

    checkInput() {
        var valid = true;
        for (var f of this.allFields) {
            if (f.validation) {
                var buf = {};
                buf[f.key + 'empty'] = this.inputEmpty(this.state[f.key], f.textType);
                this.setState(buf);
                if (buf[f.key + 'empty']) {
                    valid = false;
                }
            }
        }
        return valid;
    }

    async messageSentDimmerOpen() {
        this.setState({ loading: true });
        if (this.checkInput()) {
            this.setState({ dimmerState: await this.props.sendHandler(this.state) ? 1 : 3 }, _ => {
                if (this.state.dimmerState === 1 && this.props.skipConfirm) {
                    this.setState({
                        loading: false,
                        dimmerState: 0,
                    })
                }
                else {
                    this.setState({ loading: false });
                }
            });
        }
        else {
            this.setState({ loading: false, dimmerState: 2 });
        }
    }

    messageSentDimmerClose() {
        if (this.state.dimmerState === 1) {
            var stateBuf = { dimmerState: 0, specialError: '' };
            for (var f of this.allFields) {
                stateBuf[f.key] = '';
            }
            this.setState(stateBuf);
        }
        else {
            this.setState({ dimmerState: 0, specialError: '' });
        }
    }

    valid(field, children) {
        if (field.validation) {
            if (typeof (field.validation) === 'string') {
                return <FormFullValidation visible={this.state[field.key + 'empty']} text={field.validation}>{children}</FormFullValidation>
            }
            else {
                return <FormFullValidation visible={this.state[field.key + 'empty']}>{children}</FormFullValidation>
            }
        }
        else {
            return children;
        }
    }

    render() {
        if (this.props.overrides) {
            for (var o of this.props.overrides) {
                if (this.state[o[0]] !== o[1]) {
                    this.setField(...o);
                }
            }
        }

        // styles
        const headerStyle = {
            color: Colors.onBackground,
            fontSize: '5vh',
        };
        const textAreaStyle = {
            minHeight: 150,
            fontFamily: "'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif",
        };
        const buttonStyle = {
            height: '40px',
            margin: '30px auto',
            display: 'block',
        };
        const dimmerCloseStyle = {
            marginTop: '20px',
        };
        const radio2GridStyle = {
            width: 'min(600px, 100%)',
            margin: '0 auto',
        };

        var rows = [];
        for (var row of this.props.fields) {
            var currentRow = [];
            for (var col of row) {
                const keyBuf = col.key;
                const cbBuf = col.onChange;
                var otherProps = {};
                for (var prop of Object.entries(col)) {
                    if (!['key', 'value', 'type', 'text', 'textType', 'validation', 'disabled', 'onChange', 'element'].includes(prop[0])) {
                        otherProps[prop[0]] = prop[1];
                    }
                }
                switch (col.type) {
                    case 'label':
                        currentRow.push(<div {...otherProps}>{col.text}</div>);
                        break;
                    case 'text':
                        currentRow.push(this.valid(col,
                            <sui.Form.Input {...otherProps} step="any" lang="en_EN" fluid disabled={col.disabled} type={col.textType} id={col.textType} name={col.key} label={col.text} value={this.state[col.key]} onChange={this.handleChange} sechandler={cbBuf} />));
                        break;
                    case 'file':
                        currentRow.push(this.valid(col,
                            <sui.Form.Input {...otherProps} action={<sui.Button icon='x' negative onClick={_ => this.setState({ [keyBuf]: '', [keyBuf + '_data']: [] })} />} value={this.state[col.key]} fluid disabled={col.disabled} type='file' name={col.key} label={col.text} onChange={this.handleUpload} sechandler={cbBuf} />));
                        break;
                    case 'textField':
                        currentRow.push(this.valid(col,
                            <sui.Form.TextArea {...otherProps} disabled={col.disabled} name={col.key} label={col.text} style={textAreaStyle} value={this.state[col.key]} onChange={this.handleChange} sechandler={cbBuf} />));
                        break;
                    case 'checkbox':
                        currentRow.push(this.valid(col,
                            <sui.Form.Field>
                                {col.toggle ?
                                    <>
                                        <div>
                                            {col.text}
                                        </div>
                                        <sui.Checkbox {...otherProps} toggle disabled={col.disabled} name={col.key} checked={this.state[col.key]} onChange={(_, e) => this.handleChange(_, { name: keyBuf, value: e.checked, sechandler: cbBuf })} />
                                    </>
                                    :
                                    <Checkbox {...otherProps} disabled={col.disabled} toggle={col.toggle} name={col.key} value={this.state[col.key]} onChange={this.handleChange} sechandler={cbBuf}>
                                        {col.text}
                                    </Checkbox>
                                }
                            </sui.Form.Field>));
                        break;
                    case 'radio':
                        currentRow.push(this.valid(col,
                            <sui.Form.Field name={col.key}>
                                {col.text}
                                <a style={{ marginRight: '20px' }} />
                                {col.choices.map((v, i) =>
                                    <sui.Radio {...otherProps} style={{ marginRight: '20px' }} disabled={col.disabled} onChange={this.handleChange} sechandler={cbBuf} label={v} name={col.key} value={i} checked={this.state[col.key] === i} />)}
                            </sui.Form.Field>));
                        break;
                    case 'radio2':
                        currentRow.push(this.valid(col,
                            <sui.Form.Field name={col.key}>
                                {col.text}
                                <sui.Grid columns={col.choices.length} stackable style={radio2GridStyle}>
                                    {col.choices.map((v, i) =>
                                        <sui.Grid.Column>
                                            <sui.Button {...otherProps} fluid disabled={col.disabled} primary={this.state[col.key] === i} secondary={this.state[col.key] !== i} onClick={_ => this.handleChange(_, { name: keyBuf, value: i, sechandler: cbBuf })}>
                                                {v}
                                            </sui.Button>
                                        </sui.Grid.Column>
                                    )}
                                </sui.Grid>
                            </sui.Form.Field>));
                        break;
                    case 'button':
                        currentRow.push(this.valid(col,
                            <sui.Button {...otherProps} style={buttonStyle} className='primaryColor' disabled={col.disabled} onClick={col.onClick}>
                                {col.text}
                            </sui.Button>));
                        break;
                    case 'submit':
                        currentRow.push(this.valid(col,
                            <sui.Button {...otherProps} style={buttonStyle} className='primaryColor' loading={this.state.loading} disabled={this.state.loading} onClick={_ => this.messageSentDimmerOpen()}>
                                {col.text}
                            </sui.Button>));
                        break;
                    case 'custom':
                        currentRow.push(this.valid(col, col.element));
                        break;
                    default:
                        currentRow.push(<div>INVALID COMPONENT</div>);
                        break;
                }
            }
            rows.push(<sui.Form.Group>{currentRow}</sui.Form.Group>);
        }

        return (
            <div>
                <sui.Form inverted widths='equal'>
                    <sui.Header as='h1' style={headerStyle}>{this.props.title}</sui.Header>
                    {rows}
                </sui.Form>

                <sui.Dimmer active={this.state.dimmerState > 0} onClickOutside={() => { }} page>
                    {this.state.dimmerState === 1 ?
                        <sui.Header as='h2' icon inverted>
                            <sui.Icon name='check' />
                            Gesendet
                            <sui.Header.Subheader>{this.props.successText || 'Ihre Anfrage wurde erfolgreich verschickt!'}</sui.Header.Subheader>
                            <sui.Button style={dimmerCloseStyle} className='primaryColor' onClick={() => this.messageSentDimmerClose()}>Okay</sui.Button>
                        </sui.Header> :
                        <sui.Header as='h2' icon inverted>
                            <sui.Icon name='x' />
                            Error
                            {this.state.specialError ?
                                <sui.Header.Subheader>{this.state.specialError}</sui.Header.Subheader>
                                : (this.state.dimmerState === 2 ?
                                    <sui.Header.Subheader>Ihre Anfrage konnte nicht verschickt werden, überprüfen Sie Ihre Eingabe.</sui.Header.Subheader> :
                                    <sui.Header.Subheader>Ihre Anfrage konnte nicht verschickt werden, versuchen Sie es später erneut.</sui.Header.Subheader>
                                )}

                            <sui.Button style={dimmerCloseStyle} className='primaryColor' onClick={() => this.messageSentDimmerClose()}>Okay</sui.Button>
                        </sui.Header>
                    }
                </sui.Dimmer>
            </div>
        );
    }
}

export default windowSize(CustomForm);
