import React from 'react';
import * as sui from 'semantic-ui-react';

import ProjectItem from './projectItem';

import firebase from 'firebase/app';
import 'firebase/database';
import Colors from '../constants/colors';

class ProjectListDB extends React.Component {
    constructor(props) {
        super(props);
        this.state = {projectData: [], loaded: false, empty: false};
    }

    componentDidMount() {
        const projectsRef = firebase.database().ref('/projects');
        projectsRef.on('value', projects => {
            if (projects.val() == null) {
                this.setState({empty: true});
                return;
            }
            const pData = [];
            projects.forEach(s => {
                pData.push(s.val());
            });
            this.setState({
                projectData: pData,
                loaded: true,
            });
        });
    }

    render() {
        if (this.state.empty) {
            return <h1 style={{color: Colors.onBackground, marginLeft: '20px'}}>Keine Projekte gefunden 🤔</h1>;
        }
        return (
            <>
                <sui.Dimmer active={!this.state.loaded}>
                    <sui.Loader />
                </sui.Dimmer>
                {this.state.projectData.map(v =>
                    <ProjectItem
                        title = {v.title}
                        bg = {v.bg}
                        content = {v.content}
                        desc = {<>{v.top ? <><i>{v.top}</i><br/><br/></> : <></>}{v.desc.split('\n').map(l => <p>{l}</p>)}</>}
                        timespan = {v.timespan}
                        production = {v.production}
                        director = {v.director}
                        script = {v.script}
                    />
                )}
            </>
        );
    }
}

export default ProjectListDB;
