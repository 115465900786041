import React from 'react';
import * as sui from 'semantic-ui-react';

// constants
import Colors from '../constants/colors';

// addons
import windowSize from 'react-window-size';
import { isMobile } from 'react-device-detect';

// elements
import { MenuBarMargin } from '../elements/menuBar';
import WideUI from '../elements/wideUI';
import CustomForm from '../elements/customForm';

class SpendenPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sum: '50.00',
            company: false,
            next: false,
            firstData: {},
        };

        this.handleNext = e => {
            this.setState({
                next: true,
                firstData: e,
            });
            return true;
        };
    }

    sumChange(e) {
        this.setState({
            sum: e.target.value,
        });
    }

    sumBlur() {
        this.setState({
            sum: (+this.state.sum).toFixed(2),
        });
    }

    render() {
        var shownFields = [
            ['Firmenspende', _ => this.state.company ? 'Ja' : 'Nein']
        ];
        if (this.state.company) {
            shownFields.push(
                ['Firmenname', _ => this.state.firstData.business || ''],
                ['Telefon', _ => this.state.firstData.phone || '']
            );
        }
        shownFields.push(
            [this.state.company ? 'Ansprechpartner' : 'Name', _ => `${this.state.firstData.name} ${this.state.firstData.surname}`],
            ['E-Mail', _ => this.state.firstData.mail],
            ['Anschrift', _ => `${this.state.firstData.street} ${this.state.firstData.houseNumber}, ${this.state.firstData.plz} ${this.state.firstData.city}`],
            ['Spendenrythmus', _ => ['Einmalig', 'Monatlich', 'Jährlich'][this.state.firstData.repeat]],
            ['Betrag', _ => this.state.sum + '€'],
            ['Zahlungsart', _ => ['SEPA Lastschrift', 'PayPal', 'Direktüberweisung'][this.state.firstData.payment]]
        );

        // styles
        const backgroundStyle = {
            backgroundColor: Colors.background,
            minHeight: '100vh',
            padding: '20px 0',
        };
        const headerStyle = {
            color: Colors.onBackground,
            fontSize: '5vh',
        };
        const subheaderStyle = {
            color: Colors.onBackground,
            fontSize: '3vh',
        };
        const textStyle = {
            fontSize: isMobile ? '19px' : '23px',
            lineHeight: isMobile ? '1.4' : '1.7',
            fontFamily: '\'Montserrat\', sans-serif',
            textAlign: isMobile ? '' : 'justify',
            marginBottom: '8px',
        };
        const smileParentStyle = {
            display: 'flex',
        };
        const smilePercStyle = {
            textAlign: 'center',
            fontSize: isMobile ? '23px' : '28px',
        };
        const smileStyle = {
            display: 'inline-block',
            margin: '10px auto',
        };
        const sumStyle = {
            display: 'block',
            margin: 'auto',
            width: '150px',
        };
        const companyStyle = {
            color: Colors.onBackground,
            fontSize: '2vh',
        };
        const imgStyle = {
            pointerEvents: 'none',
        };

        return (
            <div style={backgroundStyle}>
                <MenuBarMargin />
                <WideUI>
                    <sui.Header style={headerStyle}>
                        Spenden
                    </sui.Header>
                    <div style={textStyle}>
                        Die Arbeit des Fetzwerk - Filmfördervereins und damit auch die Initiierung und Unterstützung von Filmprojekten ist nur aufgrund von Spenden möglich. Wir sind deshalb dankbar über jede kleine oder große Spende. Wenn Sie uns unterstützen wollen, Jugendlichen und jungen Erwachsenen einen Einblick in die Welt des Filmemachens zu geben, dann können Sie das auf die unterschiedlichsten Wegen tun.
                    </div>
                    <div style={textStyle}>
                        Sie können uns über Amazon Smile unterstützen. Amazon Smile ist dasselbe Amazon, das sie bereits kennen. Es gibt dieselben Produkte, Preise und den gleichen Service. Für jeden qualifizierten Kauf spendet hierbei allerdings Amazon
                        <br />
                        <div style={smilePercStyle}>
                            <b>
                                0,5 %
                            </b>
                        </div>
                        des Kaufpreises an unseren Verein. Wir würden uns also freuen, wenn Sie den Fetzwerk - Filmförderverein e.V. bei Amazon Smile auswählen würden, um uns so etwas zu unterstützen.
                    </div>
                    <div style={smileParentStyle}>
                        <a style={smileStyle} target='_blank' rel="noopener noreferrer" href='https://smile.amazon.de/ch/33-270-16773'>
                            <sui.Button color='orange'>
                                <sui.Image src='/images/logo/logo_smile.png' size='small' />
                            </sui.Button>
                        </a>
                    </div>
                    <div style={textStyle}>
                        Sie können uns auch direkt spenden. Füllen Sie einfach die gewünschte Zahlungsoption im Spendenformular aus.
                    </div>
                    <br />
                    {!this.state.next &&
                        <CustomForm sendHandler={this.handleNext} skipConfirm title='Direkt spenden' fields={[
                            [
                                {
                                    type: 'radio2',
                                    key: 'repeat',
                                    text: <>Spendenrythmus</>,
                                    choices: [
                                        'Einmalig',
                                        'Monatlich',
                                        'Jährlich',
                                    ],
                                    validation: true,
                                },
                            ],
                            [
                                {
                                    type: 'custom',
                                    key: 'sum',
                                    element: <div style={sumStyle}>
                                        <sui.Form.Input label='Betrag' fluid type='number' float min='5' step='0.01' icon='euro'
                                            value={this.state.sum} onChange={e => this.sumChange(e)} onBlur={_ => this.sumBlur()} />
                                    </div>,
                                },
                            ],
                            [
                                {
                                    type: 'radio2',
                                    key: 'payment',
                                    text: <>Zahlungsart</>,
                                    choices: [
                                        <sui.Image style={imgStyle} centered src='/images/logo/logo_sepa.png' size='small' />,
                                        <sui.Image style={imgStyle} centered src='/images/logo/logo_paypal.png' size='small' />,
                                        <sui.Image style={imgStyle} centered src='/images/logo/logo_uberweisung.png' size='small' />,
                                    ],
                                    onChange: v => this.setState({ payment: v }),
                                    validation: true,
                                },
                            ],
                            [
                                {
                                    type: 'label',
                                    text: 'Angaben zum ' + (this.state.payment === 0 ? 'Kontoinhaber' : 'Spender'),
                                },
                            ],
                            this.state.company ? [] : [
                                {
                                    type: 'text',
                                    key: 'name',
                                    text: <>Vorname</>,
                                    validation: true,
                                },
                                {
                                    type: 'text',
                                    key: 'surname',
                                    text: <>Nachname</>,
                                    validation: true,
                                },
                            ],
                            this.state.company ? [] : [
                                {
                                    type: 'text',
                                    textType: 'email',
                                    key: 'mail',
                                    text: <>E-Mail</>,
                                    validation: true,
                                },
                            ],
                            this.state.company ? [
                                {
                                    type: 'text',
                                    key: 'business',
                                    text: <>Firmenname</>,
                                    validation: true,
                                },
                            ] : [],
                            [
                                {
                                    type: 'text',
                                    key: 'street',
                                    text: <>Straße</>,
                                    validation: true,
                                },
                                {
                                    type: 'text',
                                    textType: 'number',
                                    key: 'houseNumber',
                                    text: <>Hausnummer</>,
                                    validation: true,
                                },
                            ],
                            [
                                {
                                    type: 'text',
                                    textType: 'number',
                                    key: 'plz',
                                    text: <>PLZ</>,
                                    validation: true,
                                },
                                {
                                    type: 'text',
                                    key: 'city',
                                    text: <>Ort</>,
                                    validation: true,
                                },
                            ],
                            this.state.company ? [
                                {
                                    type: 'label',
                                    text: 'Ansprechpartner',
                                },
                            ] : [],
                            this.state.company ? [
                                {
                                    type: 'text',
                                    key: 'name',
                                    text: <>Vorname</>,
                                    validation: true,
                                },
                                {
                                    type: 'text',
                                    key: 'surname',
                                    text: <>Nachname</>,
                                    validation: true,
                                },
                            ] : [],
                            this.state.company ? [
                                {
                                    type: 'text',
                                    textType: 'email',
                                    key: 'mail',
                                    text: <>E-Mail</>,
                                    validation: true,
                                },
                            ] : [],
                            this.state.company ? [
                                {
                                    type: 'text',
                                    textType: 'tel',
                                    key: 'phone',
                                    text: <>Telefon (optional)</>,
                                },
                            ] : [],
                            [
                                {
                                    type: 'custom',
                                    element:
                                        <>
                                            <br />
                                            <sui.Header style={companyStyle}>
                                                Als Firma spenden:
                                                <sui.Radio style={{ marginLeft: '20px' }} toggle checked={this.state.company} onChange={_ => this.setState({ company: !this.state.company })} />
                                            </sui.Header>
                                        </>
                                },
                            ],
                            [
                                {
                                    type: 'submit',
                                    text: 'Weiter',
                                },
                            ],
                        ]} />
                    }
                    {this.state.next &&
                        <>
                            <div style={subheaderStyle}>
                                Angegebene Daten
                            </div>
                            <sui.Grid celled>
                                {shownFields.map(e =>
                                    <sui.Grid.Row columns={2}>
                                        <sui.Grid.Column>
                                            {e[0]}
                                        </sui.Grid.Column>
                                        <sui.Grid.Column>
                                            {e[1]()}
                                        </sui.Grid.Column>
                                    </sui.Grid.Row>
                                )}
                            </sui.Grid>
                            {/* TODO: ASYNC HANDLER */}
                            <CustomForm sendHandler={e => { console.log({ ...this.state.firstData, bic: e.bic, iban: e.iban, institut: e.institut }); return true; }} title='Spende abschicken' successText='Vielen Dank, Ihre Spende wird bald bearbeitet!' overrides={[['institut', 'Haspa? Ist in 0,9% der Fälle richtig.']]} fields={[
                                [
                                    {
                                        type: 'text',
                                        key: 'iban',
                                        text: <>IBAN</>,
                                        value: '',
                                        validation: true,
                                    },
                                ],
                                [
                                    {
                                        type: 'text',
                                        key: 'bic',
                                        text: <>BIC</>,
                                        value: '',
                                        validation: true,
                                    },
                                    {
                                        type: 'text',
                                        key: 'institut',
                                        text: <>Kreditinstitut</>,
                                        value: '',
                                        disabled: true,
                                    },
                                ],
                                [
                                    {
                                        type: 'button',
                                        key: 'Abschicken',
                                        text: 'Zurück',
                                        onClick: _ => this.setState({ next: false }),
                                    },
                                    {
                                        type: 'submit',
                                        text: 'Abschicken',
                                    },
                                ],
                            ]} />
                        </>
                    }
                </WideUI>
            </div>
        );
    }
}

export default windowSize(SpendenPage);
