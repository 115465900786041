import React from 'react';
import * as sui from 'semantic-ui-react';
import './home.css';

// addons
import windowSize from 'react-window-size';
import { isChrome, isMobile, isMobileSafari } from 'react-device-detect';
import Style from 'style-it';

// constants
import Colors from '../constants/colors';

// elements
import { MenuBarHeight } from '../elements/menuBar.js';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import WideUI from '../elements/wideUI';
import CustomImg from '../elements/customImg';
import CustomSuiImg from '../elements/customSuiImg';
import ScrollFade from '../elements/scrollFade';
import ZoomClick from '../elements/zoomClick';

class HomePage extends React.Component {
  constructor(props) {
    super(props);
    // py
    // import os
    // print(os.listdir('.'))
    this.images = [
      'DSC00723.JPG', 'DSC01318.JPG', 'DSC03219.JPG', 'DSC03293.JPG', 'DSC03828-3.JPG', 'DSC04207.JPG', 'DSC05519.JPG', 'DSC06807.JPG', 'DSC06835.JPG', 'DSC09782.JPG', 'DSC_8404.jpg', 'IMG_0018.jpg', 'IMG_0044.jpg', 'IMG_0167.jpg', 'IMG_0198.jpg', 'IMG_6920.JPG', 'IMG_7447.JPG', 'Wahltag_090.JPG', 'Wahltag_171.JPG', 'Wahltag_465.JPG', 'Wahltag-Donnerstag-66.jpg', 'WahltagGruppenfoto_005.JPG'
    ];
    this.state = {};
    // +60px on mobile because of url bar
    this.handleScrollBtn = () => window.scrollTo({top: this.props.windowHeight - MenuBarHeight() + ((isMobile && isChrome) ? 60 : (isMobileSafari ? 70 : 0)), behavior: 'smooth'});
  }

  render() {
    const landscape = this.props.windowWidth > this.props.windowHeight;
    const imgVisible = this.props.windowWidth > 1260;

    // styles
    const infoBackgroundStyle = {
      backgroundColor: Colors.background,
      width: '100%',
    }
    const backgroundStyle = {
      backgroundImage: `linear-gradient(#00000090, #000000C0), url(${"images/bg/background_index.jpg"})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      width: '100%',
      height: '100vh',
    };
    const headerStyle = {
      position: 'absolute',
      top: landscape ? 'calc(50vh - 5vw)' : 'calc(50vh - 20vw)',
    };
    const logoStyle = {
      width: landscape ? '27%' : '80%',
      height: 'auto',
    }
    const scrollBtnStyle = {
      position: 'relative',
      top: landscape ? 'calc(25vh - 5vw)' : 'calc(25vh - 8vw)',
      display: 'block',
      marginLeft: 'auto',
      marginRight: 'auto',
      transition: 'background-color 200ms linear',
    };
    const textStyle = {
      marginTop: landscape ? (imgVisible ? 'calc(4vh + 8.5vw - 100px)' : 'calc(2vh + 10vw - 100px)') : '',
      textAlign: isMobile ? '' : 'justify',
      fontFamily: '\'Montserrat\', sans-serif',
    };
    const textImgStyle = {
      display: imgVisible ? '' : 'none',
      margin: '12px 0 25px 25px',
      boxShadow: '2px 2px 5px #00000080',
    };
    const galleryStyle = {
      width: landscape ? '50%' : '85%',
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: 'calc(40vh - 10vw + 20px)',
      cursor: 'pointer',
    };
    const galleryShadowStyle = {
      boxShadow: '3px 3px 10px #00000060',
    };
    const imgStyle = {
      width: '100%',
      height: '50%',
    };

    return (
      <div style={infoBackgroundStyle}>
        <div style={backgroundStyle}>
          <div style={headerStyle}>
            <CustomSuiImg centered src='images/logo/logo_fetzwerk2.png' style={logoStyle} size='massive' className='noSelect'></CustomSuiImg>
            <Style>
            {`
            .scrollBtn:hover {
              background-color: ` + Colors.primary + `;
            }`}
              <sui.Button className='scrollBtn' circular icon style={scrollBtnStyle} onClick={this.handleScrollBtn}>
                <sui.Icon name='angle down' size='large' />
              </sui.Button>
            </Style>
          </div>
        </div>
        <div style={textStyle}>
          <WideUI width={landscape ? '75%' : ''} fontSize='24px' lineHeight={isMobile ? '1.4' : '1.8'}>
            <CustomSuiImg style={textImgStyle} floated='right' size='large' src='/images/gallery/DSC09656.JPG' />
            Der "Fetzwerk - Filmförderverein e.V." entstand aus der Idee, Filmemacher aus ganz Deutschland miteinander zu verbinden.
            Da wir aus eigener Erfahrung wissen wie schwer es sein kann Filme zu produzieren, verfolgen wir das Ziel Filmemacher und vor allem Nachwuchsfilmemacher, zu denen auch die meisten Mitglieder unseres Vereins zählen, bei der Umsetzung ihrer Projekte zu unterstützen.
            <br/><br/>
            Sei es die Finanzierung, die Organisation oder die Suche nach einem passenden Team, oftmals treten bei der Realisierung die gleichen Schwierigkeiten auf.
            Hier wollen wir mit einem deutschlandweiten Netzwerk aus Mitgliedern vermitteln und den Kontakt zu anderen jungen Filmemachern herstellen.
            Ziel ist es, dass sich junge Kreative besser zusammentun können, ihre Ressourcen und ihre Fähigkeiten bündeln, um gemeinsam voneinander zu lernen.
            Durch diese gegenseitige Hilfestellung wollen wir viele engagierte Filmprojekte ermöglichen, die ansonsten nicht realisiert werden könnten.
          </WideUI>
        </div>
        <ScrollFade threshold={0.3}>
          <div style={galleryStyle}>
            <div style={galleryShadowStyle}>
              <Carousel showStatus={false} showIndicators={!isMobile} showThumbs={false} infiniteLoop={true} autoPlay={true} interval={5000} transitionTime={750}>
                {this.images.map((v, k) =>
                  <ZoomClick content={<CustomImg key={k} src={'/images/galleryhd/' + v} style={{width: '100%'}} />}>
                    <div><CustomImg key={k} src={'/images/gallery/' + v} style={imgStyle} /></div>
                  </ZoomClick>
                )}
              </Carousel>
            </div>
          </div>
        </ScrollFade>
        <div style={{height: 'calc(40vh - 10vw - 68px)'}} />
      </div>
    );
  }
}

export default windowSize(HomePage);
