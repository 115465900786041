import React from 'react';
import * as sui from 'semantic-ui-react';

import ProjectItemEditor from './projectItemEditor';

import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';
import Colors from '../constants/colors';
import DraggableList from 'react-draggable-list';

// TODO: WORK AND PRODUCTION REFS

class ProjectEditorList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {dbRefs: [], titles: [], deleteValue: '0', loaded: false, empty: false, loginDisabled: false, loginError: 0};
        firebase.database().ref('projects').on('value', projects => {
            if (projects.val() === null) {
                this.setState({empty: true});
                return;
            }
            const refs = [];
            const titles = [];
            projects.forEach(p => {
                refs.push(p.ref);
                titles.push(p.val().title);
            });
            this.setState({
                dbRefs: refs,
                titles: titles,
                loaded: true,
            });
        });

        this.onCreate = () => {
            if (this.state.loginDisabled) {
                firebase.database().ref('nextID').once('value', s => {
                    const refs = this.state.dbRefs;
                    const newOrder = ['/projects/' + s.val(), ...refs.map(r => r.path.toString())];
                    firebase.database().ref('projects/' + s.val()).set({
                        bg: '/images/bg/background_index.jpg',
                        content: ['/images/bg/background_construction.jpg'],
                        desc: '',
                        info: '',
                        title: 'Projekt ' + s.val(),
                        top: 'In Arbeit',
                    }, () => {
                        this.sortBy(newOrder);
                    });
                    firebase.database().ref('nextID').set(s.val() + 1);
                });
            }
        };
        this.onDelete = () => {
            if (this.state.loginDisabled) {
                this.state.dbRefs[+this.state.deleteValue].remove();
                var delValue = +this.state.deleteValue;
                if (delValue >= this.state.dbRefs.length) {
                    delValue = 0;
                }
                this.setState({deleteConfirm: false, deleteValue: delValue.toString()});
            }
        };
        this.onLogin = () => {
            firebase.auth().signOut().then(() => {
                firebase.auth().signInWithEmailAndPassword("mirco.heitmann@gmail.com", this.state.passValue)
                    .then(() => {
                        this.setState({loginDisabled: true, loginError: -1});
                    })
                    .catch(e => {
                        if (e.code === 'auth/wrong-password') {
                            this.setState({loginError: 1});
                        }
                        else if (e.code === 'auth/too-many-requests') {
                            this.setState({loginError: 2});
                        }
                        else {
                            this.setState({loginError: 3});
                            console.log(e);
                        }
                    });
            });
        }
        this.sortBy = sortList => {
            if (this.state.loginDisabled) {
                const newList = new Array(sortList.length);
                var counter = 0;
                for (var i in sortList) {
                    const thisIndex = i;
                    firebase.database().ref(sortList[thisIndex]).once('value', s => {
                        newList[thisIndex] = s.val();
                        counter++;
                        if (counter >= sortList.length) {
                            firebase.database().ref('projects').set(newList);
                            firebase.database().ref('nextID').set(newList.length);
                        }
                    });
                }
            }
        }
    }

    render() {
        // styles
        const btnContainerStyle = {
            textAlign: 'center',
        };
        const dimmerCloseStyle = {
            marginTop: '20px',
        };

        return (
            <>
                <sui.Container>
                    <sui.Form>
                        <sui.Input value={this.state.passValue} onChange={v => this.setState({passValue: v.target.value})} fluid label='Password' type='password' autocomplete='current-password' />
                        <sui.Button disabled={this.state.loginDisabled} onClick={this.onLogin} fluid className='primaryColor'>Login</sui.Button>
                    </sui.Form>
                </sui.Container>
                <br />
                {this.state.empty ?
                    <h1 style={{color: Colors.onBackground, marginLeft: '20px'}}>Keine Projekte vorhanden</h1>
                : <>
                    <sui.Dimmer active={!this.state.loaded}>
                        <sui.Loader />
                    </sui.Dimmer>
                    <DraggableList itemKey='name' template={ProjectItemEditor} list={this.state.dbRefs.map((v, k) => {
                        return {name: v.path.toString(), dbr: v, loggedIn: this.state.loginDisabled};
                    })} onMoveEnd={l => this.sortBy(l.map(e => e.name))} />
                </>}
                <div style={btnContainerStyle}>
                    <sui.Button className='primaryColor' onClick={this.onCreate} disabled={!this.state.loginDisabled}>
                        <sui.Icon name='plus' />
                        Neues Projekt
                    </sui.Button>
                    <br />
                    <br />
                    <sui.Select disabled={!this.state.loginDisabled} value={this.state.deleteValue} onChange={(_,v) => {this.setState({deleteValue: v.value})}} options={this.state.titles.map((v, k) => { return {key: k.toString(), value: k.toString(), text: v}; })} />
                    <sui.Button negative onClick={() => this.setState({deleteConfirm: true})} disabled={!this.state.loginDisabled}>
                        <sui.Icon name='x' />
                        Projekt löschen
                    </sui.Button>
                </div>
                <sui.Dimmer active={this.state.deleteConfirm} onClickOutside={() => {}} page>
                    <sui.Header as='h2' icon inverted>
                        <sui.Icon name='warning' />
                        ACHTUNG!
                        <sui.Header.Subheader>Sicher, dass du das Projekt <b style={{fontSize: '20px', margin: '0 5px'}}>{this.state.titles[+this.state.deleteValue]}</b> <i>unwiderruflich</i> löschen willst?</sui.Header.Subheader>
                        <sui.Button style={dimmerCloseStyle} negative onClick={this.onDelete}>Ja, sicher</sui.Button>
                        <sui.Button style={dimmerCloseStyle} className='primaryColor' onClick={() => this.setState({deleteConfirm: false})}>Bitte tu das nicht</sui.Button>
                    </sui.Header>
                </sui.Dimmer>
                <sui.Dimmer active={this.state.loginError !== 0} onClickOutside={() => {}} page>
                    <sui.Header as='h2' icon inverted>
                        <sui.Icon name={this.state.loginError < 0 ? 'check' : 'warning'} />
                        <sui.Header.Subheader>{
                            this.state.loginError === -1 ? 'Hat geklappt' :
                            this.state.loginError === 1 ? 'Das Passwort war falsch' : 
                            this.state.loginError === 2 ? 'Na toll, jetzt ist der Login gesperrt. Versuch\'s später, aber mit dem richtigen Passwort :)' : 
                            'Hier ist was schief gelaufen'
                        }</sui.Header.Subheader>
                        <sui.Button style={dimmerCloseStyle} primary onClick={() => this.setState({loginError: 0})}>Das ist ok</sui.Button>
                    </sui.Header>
                </sui.Dimmer>
            </>
        );
    }
}

export default ProjectEditorList;
