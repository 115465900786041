// also in index.css:38 and index.css:43

class Colors {
    static primary = '#107c41';
    static onPrimary = '#FFFFFF';

    static secondary = '#607d61';
    static onSecondary = '#FFFFFF';

    static background = '#202020';
    static onBackground = '#FFFFFF';

    static error = '#CC0000';
    static onError = '#FFFFFF';

    static a = '#808080';
    static aHover = '#A0A0A0';
}

export default Colors;
