import React from 'react';
import * as sui from 'semantic-ui-react';

class ZoomClick extends React.Component {
    constructor(props) {
        super(props);
        this.state = {open: false};
    }

    render() {
        // styles
        const closeButtonStyle = {
            position: 'absolute',
            right: '2vw',
            top: '2vw',
            boxShadow: '3px 3px 5px',
        }

        return (
            <div>
                <sui.Modal
                    style={{backgroundColor: '#00000000'}}
                    size='large'
                    onOpen={() => this.setState({open: true})}
                    onClose={() => this.setState({open: false})}
                    open={this.state.open}
                    trigger={this.props.children}>
                    {this.props.content}
                    <div>
                        <sui.Button style={closeButtonStyle} className='primaryColor' circular={true} icon onClick={() => this.setState({open: false})}>
                            <sui.Icon name='close' />
                        </sui.Button>
                    </div>
                </sui.Modal>
            </div>
        );
    }
}

export default ZoomClick;
