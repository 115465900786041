import React from 'react';
import * as sui from 'semantic-ui-react';

import Colors from '../constants/colors';

class Checkbox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {value: this.props.value || false};
        this.toggleHandler = () => {
            if (!this.props.disabled) {
                const newValue = !this.state.value
                this.setState({value: newValue});
                if (this.props.onChange) {
                    this.props.onChange({}, {
                        name: this.props.name,
                        value: newValue
                    });
                }
            }
        };
    }

    render() {
        if (this.props.value !== this.state.value) {
            this.setState({value: this.props.value});
        }

        const rootStyle = {
            display: 'inline',
            position: 'relative',
            cursor: this.props.disabled ? '' : 'pointer',
        };
        const posTopLeft = {
            position: 'absolute',
            top: 0,
            left: 0,
        };
        const boxStyle = {
            ...posTopLeft,
            width: '20px',
            height: '20px',
        };
        const boxBackgroundStyle = {
            ...posTopLeft,
            backgroundColor: this.props.disabled ? '#A0A0A0' : '#FFFFFF',
            borderRadius: 4,
            width: '100%',
            height: '100%',
            zIndex: 0,
        };
        const checkStyle = {
            ...posTopLeft,
            display: this.state.value ? '' : 'none',
            zIndex: 100,
            marginLeft: this.props.checkMarginLeft || '2px',
            marginTop: this.props.checkMarginTop || '-3px',
            color: Colors.primary //'#000000',
        };
        const textStyle = {
            marginLeft: 25,
            cursor: this.props.disabled ? '' : 'pointer',
        };

        return (
            <div onClick={this.toggleHandler} style={rootStyle}>
                <div style={boxStyle}>
                    <sui.Icon style={checkStyle} name='check' />
                    <div style={boxBackgroundStyle} />
                </div>
                <label className='ui text' style={textStyle}>{this.props.children}</label>
            </div>
        );
    }
}

export default Checkbox;
