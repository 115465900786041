import React from 'react';
import * as sui from 'semantic-ui-react';

class ScrollTopBtn extends React.Component {
    static windowScroll = 0;

    static isVisible() {
        return this.windowScroll > 600;
    }

    handleScroll(self) {
        ScrollTopBtn.windowScroll = window.pageYOffset;
        if (ScrollTopBtn.isVisible() && self.state.opacity !== 1.0) {
            self.setState({ opacity: 1.0 });
            self.setState({ visibility: 'visible' });
        }
        if (!ScrollTopBtn.isVisible() && self.state.opacity !== 0.0) {
            self.setState({ opacity: 0.0 });
            setTimeout(() => {
                self.setState({ visibility: 'hidden' });
            }, 250);
        }
    }

    componentDidMount() {
        const self = this;
        window.addEventListener('scroll', () => this.handleScroll(self));
    }

    componentWillUnmount() {
        const self = this;
        window.removeEventListener('scroll', () => this.handleScroll(self));
    }

    handleScrollBtn() {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    constructor(props) {
        super(props);
        this.state = { display: false, opacity: 0.0 };
    }

    render() {
        const rootStyle = {
            position: 'fixed',
            right: '22px',
            bottom: '22px',
            zIndex: '1000',
        };
        const btnStyle = {
            opacity: this.state.opacity,
            visibility: this.state.visibility,
            transition: 'opacity 250ms',
        };

        return (
            <div style={rootStyle}>
                <sui.Button circular icon style={btnStyle} className='primaryColor' onClick={this.handleScrollBtn}>
                    <sui.Icon name='angle up' size='large' />
                </sui.Button>
            </div>
        );
    }
}

export default ScrollTopBtn;
