import React from 'react';
import * as sui from 'semantic-ui-react';

// addons
import windowSize from 'react-window-size';

// constants
import Colors from '../constants/colors';

// elements
import { MenuBarMargin } from '../elements/menuBar';
import ProjectItem from '../elements/projectItem';
import WideUI from '../elements/wideUI';
import ProjectListDB from '../elements/projectListDB';

class ProjectsPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    onComponentDidMount() {
    }

    render() {
        // styles
        const backgroundStyle = {
            backgroundColor: Colors.background,
            minHeight: '100vh',
            padding: '20px 0',
        };
        const headerStyle = {
            color: Colors.onBackground,
            fontSize: '5vh',
        };

        return (
            <div style={backgroundStyle}>
                <MenuBarMargin />
                <WideUI>
                    <sui.Header as='h1' style={headerStyle}>Unsere Projekte</sui.Header>
                </WideUI>
                <ProjectListDB />
            </div>
        );
    }
}

export default windowSize(ProjectsPage);
