import React from 'react';
import * as sui from 'semantic-ui-react';

// addons
import windowSize from 'react-window-size';
import Colors from '../constants/colors';

import WideUI from '../elements/wideUI';
import { MenuBarMargin } from '../elements/menuBar';

class ImpressumPage extends React.Component {
  render() {
    const singleCol = this.props.windowWidth < 650;
    const colCount = singleCol ? 1 : 3;

    // styles
    const headerStyle = {
      color: Colors.onBackground,
      fontSize: '5vh',
    };
    const tableStyle = {
      lineHeight: '2.0',
    };
    const colStyle1 = {
      maxWidth: '400px',
    };
    const colStyle2 = {
      ...colStyle1,
      boxShadow: singleCol ? 'none' : '-1px 0px 0px 0px #ffffffb0',
    };

    return (
      <div style={{ backgroundColor: Colors.background }}>
        <WideUI>
          <MenuBarMargin />
          <sui.Header as="h1" style={headerStyle}>Impressum</sui.Header>
          <div style={{ fontSize: 17 }}>
            <br />
            <h2>FETZWERK - Filmförderverein e.V.</h2>
            <div>Angaben gemäß § 5 TMG</div><br />
            <sui.Grid columns={colCount} style={tableStyle} divided>
              <sui.Grid.Row style={{justifyContent: 'center'}}>
                <sui.Grid.Column style={colStyle1}>
                  <sui.Grid.Row><h2>Vertreten durch:</h2></sui.Grid.Row>
                  <sui.Grid.Row><b>1. Vorstandsvorsitzender: </b><a href="mailto: t.kraaz@fetzwerk.net">Timon Kraaz</a></sui.Grid.Row>
                  <sui.Grid.Row><b>Kassenwart: </b><a href="mailto: f.kunde@fetzwerk.net">Felix Kunde</a></sui.Grid.Row>
                  <sui.Grid.Row><b>Öffentlichkeitsbeauftragter: </b><a href="mailto: l.seidler@fetzwerk.net">Linus Seidler</a></sui.Grid.Row>
                  <br />
                </sui.Grid.Column>
                <sui.Grid.Column style={colStyle2}>
                  <sui.Grid.Row><h2>Kontakt:</h2></sui.Grid.Row>
                  <sui.Grid.Row><b>Adresse: </b><a target="_blank" rel="noopener noreferrer" href='https://goo.gl/maps/q2bccG5rKhhyn175A'>Wallstraße 14, 21357 Bardowick</a></sui.Grid.Row>
                  <sui.Grid.Row><b>Telefon: </b><a href='tel: +49 15678 343423'>+49 15678 343423</a></sui.Grid.Row>
                  <sui.Grid.Row><b>E-Mail: </b><a href='mailto: info@fetzwerk.net'>info@fetzwerk.net</a></sui.Grid.Row>
                  <br />
                </sui.Grid.Column>
                <sui.Grid.Column style={colStyle2}>
                  <sui.Grid.Row><h2>Registereintrag:</h2></sui.Grid.Row>
                  <sui.Grid.Row><b>Registergericht: </b>Amtsgericht Lüneburg</sui.Grid.Row>
                  <sui.Grid.Row><b>Registernummer: </b>VR 201722</sui.Grid.Row>
                  <br />
                </sui.Grid.Column>
              </sui.Grid.Row>
            </sui.Grid>
            <br />
            <p>Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit, die Sie <a href="https://ec.europa.eu/consumers/odr/">hier</a> finden. Zur Teilnahme an einem Streitbeilegungsverfahren vor einer
          Verbraucherschlichtungsstelle sind wir nicht verpflichtet und nicht bereit.</p>
            <br />
            <h2>Haftungsausschluss</h2>
            <br />
            <h3>Haftung für Inhalte</h3>
            <p>Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen. Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte
            auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen,
            die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt
            der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.</p>
            <br />
            <h3>Haftung für Links</h3>
            <p>Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige
            Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente
            inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.</p>
            <br />
            <h3>Urheberrecht</h3>
            <p>Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen
            der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden,
            werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden
            von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.</p>
            <br />
          </div>
        </WideUI>
      </div>
    );
  }
}

export default windowSize(ImpressumPage);
