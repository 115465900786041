import React from 'react';
import { Link } from 'react-router-dom';
import windowSize from 'react-window-size';

class Footer extends React.Component {
    constructor(props) {
        super(props);
        this.margin = 0;
        this.marginSettled = false;
    }

    render() {
        if (!this.marginSettled) {
            var elem = document.getElementById('footer');
            if (elem) {
                setTimeout(() => {
                    var style = elem.currentStyle || window.getComputedStyle(elem);
                    if (style.marginTop === '0px') {
                        const rect = elem.getBoundingClientRect();
                        if (rect.y < this.props.windowHeight) {
                            this.margin = 7 + this.props.windowHeight - rect.y;
                        }
                    }
                    else {
                        this.margin = style.marginTop;
                    }
                }, 10);
                this.marginSettled = true;
            }
        }

        if (this.marginSettled) {
            // styles
            const mainStyle = {
                backgroundColor: '#000000',
                color: '#ffffff',
                width: '100%',
                fontSize: 17,
                textAlign: 'center',
                padding: 25,
                marginTop: this.margin,
            };

            return (
                <footer style={mainStyle} id='footer'>
                    &copy; 2020-{new Date().getFullYear()} <Link to="/">Fetzwerk - Filmförderverein e.V.</Link> | Alle Rechte vorbehalten.
                    <br />
                    <Link to="/impressum">Impressum</Link> | <Link to="/datenschutz">Datenschutz</Link>
                    <div style={{ position: 'absolute', right: 0, color: '#333' }}>V2.1</div>
                </footer>
            );
        }
        else {
            return <div id='footer'></div>;
        }
    }
}

export default windowSize(Footer);
