import React from 'react';
import * as sui from 'semantic-ui-react';

// addons
//import hookcord from 'hookcord';
import windowSize from 'react-window-size';

// elements
import { MenuBarMargin } from '../elements/menuBar.js';
import Colors from '../constants/colors';
import SlimUI from '../elements/slimUI';
import CustomForm from '../elements/customForm';

import Network from '../constants/network';

class AntragPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.onAccordionClick = (c) => {
      this.setState({ ['accordionOpen' + c.currentTarget.attributes["name"].value]: !this.state['accordionOpen' + c.currentTarget.attributes["name"].value] });
    };
    this.formRef = React.createRef();
  }

  async sendApplication(data) {
    /*
    // DISCORD
    const hook = new hookcord.Hook();
    hook.login('718499585315700806', process.env.REACT_APP_WEBHOOK_SECRET);
    hook.setPayload({
      'embeds': [{
        'title': 'Mitgliedsanfrage',
        'color': 12924196,
        'author': {
          'name': data.name + ' ' + data.surname,
          'icon_url': 'https://cdn.discordapp.com/embed/avatars/0.png',
        },
        'fields': Object.keys(data).map((k) => {
          if (data[k] === '' || data[k] === false) {
            return {
              'name': k || '',
              'value': 'false',
            };
          }
          else if (data[k] === true) {
            return {
              'name': k || '',
              'value': 'true',
            };
          }
          else {
            return {
              'name': k || '',
              'value': data[k] || '',
            }
          }
        }),
      }],
    });
    return hook.fire().then(() => {
      return true;
    }).catch(error => {
      console.error(error);
      return false;
    });
    */

    // BACKEND

    if (new URLSearchParams(document.location.search).get('type') === 'business') { // BUSINESS
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          business: data.business,
          street: data.street,
          housenumber: data.houseNumber,
          plz: data.plz,
          city: data.city,
          salutation: data.salutation,
          surname: data.surname,
          name: data.name,
          mobile: data.mobile,
          phone: data.phone,
          mail: data.mail,
          personal: data.personal,
          satzung: data.satzung,
          privacy: data.privacy
        })
      };
      return fetch(Network.backendHost + 'business', requestOptions).then(res => {
        return res.status === 200;
      }).catch(error => {
        console.error(error);
        return false;
      });
    }
    else { // PRIVATE
      const birthdate = new Date(Date.parse(data.birthdate));
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          salutation: data.salutation,
          surname: data.surname,
          name: data.name,
          birth: birthdate.getUTCDate() + "." + (birthdate.getUTCMonth() + 1) + "." + birthdate.getUTCFullYear(),
          street: data.street,
          houseNumber: data.houseNumber,
          plz: data.plz,
          city: data.city,
          phoneMobile: data.mobile,
          phonePrivate: data.phone,
          mail: data.mail,
          personal: data.personal,
          underage: data.underage
        })
      };
      return fetch(Network.backendHost + 'application', requestOptions).then(res => {
        return res.status === 200;
      }).catch(error => {
        console.error(error);
        return false;
      });
    }
  }

  render() {
    const isBusiness = new URLSearchParams(document.location.search).get('type') === 'business';

    const mailDocuments = isBusiness ? [
      ['/FETZWERK_aufnahmeFirma.pdf', 'Aufnahmeformular (Firma)'],
      ['/FETZWERK_satzung.pdf', 'Satzung'],
      ['/FETZWERK_sepa.pdf', 'SEPA-Lastschriftmandat'],
    ] : [
      ['/FETZWERK_aufnahme.pdf', 'Aufnahmeformular'],
      ['/FETZWERK_satzung.pdf', 'Satzung'],
      ['/FETZWERK_sepa.pdf', 'SEPA-Lastschriftmandat'],
      ['/FETZWERK_underage.pdf', 'Einverständniserklärung der Erziehungsberechtigten'],
    ];

    const mailDocumentColumns = mailDocuments.length === 0 ? 1 : (this.props.windowWidth / 2 / mailDocuments.length > 200 ? mailDocuments.length : 1);

    // styles
    const mainStyle = {
      backgroundColor: Colors.background,
      minHeight: '100vh',
    };
    const onBG = {
      color: Colors.onBackground,
    };
    const accordionBGStyle = {
      backgroundColor: '#000000',
      width: '100vw',
    };
    const accordionStyle = {
      backgroundColor: '#000000',
      //padding: '20px',
    };
    const ribbonStyle = {
      backgroundColor: Colors.primary,
      color: Colors.onPrimary,
    };
    const mailDocumentStyle = {
      textAlign: mailDocumentColumns === 1 ? 'left' : 'center',
    };

    return (
      <div style={mainStyle}>
        <MenuBarMargin />
        {/*
        <sui.Header style={onBG}>Wie willst du dem Verein beitreten?</sui.Header>
        */}
        <SlimUI>
          <br />
          <CustomForm ref={this.formRef} sendHandler={this.sendApplication} overrides={this.state.formOverrides || []} title='Online-Antrag' successText='Anfrage erfolgreich versendet. Wir melden uns schnellstmöglich.' fields={
            isBusiness ? // BUSINESS
              [
                [
                  {
                    type: 'text',
                    textType: 'text',
                    key: 'business',
                    text: <>Firmenname</>,
                    value: '',
                    validation: true,
                  },
                ],
                [
                  {
                    type: 'text',
                    textType: 'text',
                    key: 'street',
                    text: <>Straße</>,
                    value: '',
                    validation: true,
                  },
                  {
                    type: 'text',
                    textType: 'number',
                    key: 'houseNumber',
                    text: <>Hausnummer</>,
                    value: '',
                    validation: true,
                  },
                ],
                [
                  {
                    type: 'text',
                    textType: 'number',
                    key: 'plz',
                    text: <>PLZ</>,
                    value: '',
                    validation: true,
                  },
                  {
                    type: 'text',
                    textType: 'text',
                    key: 'city',
                    text: <>Ort</>,
                    value: '',
                    validation: true,
                  },
                ],
                [
                  {
                    type: 'label',
                    text: 'Ansprechpartner',
                  },
                ],
                [
                  {
                    type: 'radio',
                    key: 'salutation',
                    text: <>Anrede</>,
                    choices: ['Herr', 'Frau'],
                    value: -1,
                    validation: true,
                  },
                ],
                [
                  {
                    type: 'text',
                    textType: 'name',
                    key: 'surname',
                    text: <>Name</>,
                    value: '',
                    validation: true,
                  },
                ],
                [
                  {
                    type: 'text',
                    textType: 'name',
                    key: 'name',
                    text: <>Vorname</>,
                    value: '',
                    validation: true,
                  },
                ],
                [
                  {
                    type: 'text',
                    textType: 'tel',
                    key: 'mobile',
                    text: <>Telefon 1</>,
                    value: '',
                    validation: true,
                  },
                ],
                [
                  {
                    type: 'text',
                    textType: 'tel',
                    key: 'phone',
                    text: <>Telefon 2</>,
                    value: '',
                    validation: false,
                  },
                ],
                [
                  {
                    type: 'text',
                    textType: 'email',
                    key: 'mail',
                    text: <>E-Mail</>,
                    value: '',
                    validation: true,
                  },
                ],
                [
                  {
                    type: 'textField',
                    key: 'personal',
                    text: <>Erzähle uns etwas über dein Unternehmen:</>,
                    value: '',
                    validation: false,
                  },
                ],
                [
                  {
                    type: 'checkbox',
                    key: 'satzung',
                    text: <>Die <a target="_blank" rel="noopener noreferrer" href='/FETZWERK_satzung.pdf'>Satzung</a> des Vereins Fetzwerk – Filmförderverein e.V. steht mir auf der Internetseite des Vereins zur Verfügung. Ich habe diese zur Kenntnis genommen und erkenne sie an.</>,
                    value: false,
                    validation: 'Du musst die Satzung akzeptieren',
                  },
                ],
                [
                  {
                    type: 'checkbox',
                    key: 'privacy',
                    text: <>Ich habe die <a target="_blank" rel="noopener noreferrer" href='/datenschutz'>Datenschutzerklärung</a> gelesen und akzeptiere, dass Daten gespeichert werden.</>,
                    value: false,
                    validation: 'Du musst die Datenschutzerkärung akzeptieren',
                  },
                ],
                [
                  {
                    type: 'checkbox',
                    key: 'sepa',
                    text: <>Für den Einzug der Mitgliedsbeiträge werde ich ein ausgefülltes <a target="_blank" rel="noopener noreferrer" href='/FETZWERK_sepa.pdf'>SEPA-Lastschriftmandat</a> an "Fetzwerk - Filmförderverein e.V. , Wallstraße 14, 21357 Bardowick" senden.</>,
                    value: false,
                    validation: 'Du musst die Mitgliedsbeiträge akzeptieren',
                  },
                ],
                [
                  {
                    type: 'checkbox',
                    key: 'represent',
                    text: <>Ich versichere, dass ich für das oben angegebene Unternehmen vertretungsberechtigt bin.</>,
                    value: false,
                    validation: 'Du musst vertretungsberechtigt für das Unternehmen sein, um es anzumelden',
                  },
                ],
                [
                  {
                    type: 'submit',
                    text: <>Senden</>,
                  },
                ],
              ] : [ // PRIVAT
                [
                  {
                    type: 'radio',
                    key: 'salutation',
                    text: <>Anrede</>,
                    choices: ['Herr', 'Frau'],
                    value: -1,
                    validation: true,
                  },
                ],
                [
                  {
                    type: 'text',
                    textType: 'name',
                    key: 'surname',
                    text: <>Name</>,
                    value: '',
                    validation: true,
                  },
                ],
                [
                  {
                    type: 'text',
                    textType: 'name',
                    key: 'name',
                    text: <>Vorname</>,
                    value: '',
                    validation: true,
                  },
                ],
                [
                  {
                    type: 'text',
                    textType: 'date',
                    key: 'birthdate',
                    text: <>Geburtstag</>,
                    value: '',
                    validation: true,
                    onChange: v => {
                      if (Date.parse(v)) {
                        const age = Math.abs(new Date(Date.now() - Date.parse(v)).getUTCFullYear() - 1970);
                        this.setState({ formOverrides: [['underage', age < 18]] });
                      }
                    },
                  },
                ],
                [
                  {
                    type: 'text',
                    textType: 'text',
                    key: 'street',
                    text: <>Straße</>,
                    value: '',
                    validation: true,
                  },
                  {
                    type: 'text',
                    textType: 'number',
                    key: 'houseNumber',
                    text: <>Hausnummer</>,
                    value: '',
                    validation: true,
                  },
                ],
                [
                  {
                    type: 'text',
                    textType: 'number',
                    key: 'plz',
                    text: <>PLZ</>,
                    value: '',
                    validation: true,
                  },
                  {
                    type: 'text',
                    textType: 'text',
                    key: 'city',
                    text: <>Ort</>,
                    value: '',
                    validation: true,
                  },
                ],
                [
                  {
                    type: 'text',
                    textType: 'tel',
                    key: 'mobile',
                    text: <>Telefon 1</>,
                    value: '',
                    validation: true,
                  },
                ],
                [
                  {
                    type: 'text',
                    textType: 'tel',
                    key: 'phone',
                    text: <>Telefon 2</>,
                    value: '',
                    validation: false,
                  },
                ],
                [
                  {
                    type: 'text',
                    textType: 'email',
                    key: 'mail',
                    text: <>E-Mail</>,
                    value: '',
                    validation: true,
                  },
                ],
                [
                  {
                    type: 'textField',
                    key: 'personal',
                    text: <>Erzähle uns etwas über dich:</>,
                    value: '',
                    validation: false,
                  },
                ],
                [
                  {
                    type: 'checkbox',
                    key: 'satzung',
                    text: <>Die <a target="_blank" rel="noopener noreferrer" href='/FETZWERK_satzung.pdf'>Satzung</a> des Vereins Fetzwerk – Filmförderverein e.V. steht mir auf der Internetseite des Vereins zur Verfügung. Ich habe diese zur Kenntnis genommen und erkenne sie an.</>,
                    value: false,
                    validation: 'Du musst die Satzung akzeptieren',
                  },
                ],
                [
                  {
                    type: 'checkbox',
                    key: 'privacy',
                    text: <>Ich habe die <a target="_blank" rel="noopener noreferrer" href='/datenschutz'>Datenschutzerklärung</a> gelesen und akzeptiere, dass Daten gespeichert werden.</>,
                    value: false,
                    validation: 'Du musst die Datenschutzerkärung akzeptieren',
                  },
                ],
                [
                  {
                    type: 'checkbox',
                    key: 'sepa',
                    text: <>Für den Einzug der Mitgliedsbeiträge werde ich ein ausgefülltes <a target="_blank" rel="noopener noreferrer" href='/FETZWERK_sepa.pdf'>SEPA-Lastschriftmandat</a> an "Fetzwerk - Filmförderverein e.V. , Wallstraße 14, 21357 Bardowick" senden</>,
                    value: false,
                    validation: 'Du musst die Mitgliedsbeiträge akzeptieren',
                  },
                ],
                [
                  {
                    type: 'checkbox',
                    key: 'underage',
                    text: <>Ich bin minderjährig und lege deshalb eine <a target="_blank" rel="noopener noreferrer" href='/FETZWERK_underage.pdf'>Einverständniserklärung</a> meiner Erziehungsberechtigten bei.</>,
                    value: false,
                    validation: false,
                    disabled: true,
                  },
                ],
                [
                  {
                    type: 'submit',
                    text: <>Senden</>,
                  },
                ],
              ]} />
        </SlimUI>
        <div style={accordionBGStyle}>
          <SlimUI>
            <sui.Accordion style={accordionStyle}>
              <sui.Accordion.Title active={this.state.accordionOpen0} onClick={this.onAccordionClick} style={onBG} name='0'>
                <sui.Icon name='dropdown' />
                Antrag per Post
              </sui.Accordion.Title>
              <sui.Accordion.Content active={this.state.accordionOpen0}>
                <sui.Grid.Row>
                  Wenn du den Antrag per Post einreichen möchtest, fülle bitte alle unten genannten Dokumente aus und schicke sie an:
                  <br />
                  <a target="_blank" rel="noopener noreferrer" href='https://goo.gl/maps/q2bccG5rKhhyn175A'>
                    Fetzwerk - Filmförderverein e.V.
                    <br />
                    Wallstraße 14, 21357 Bardowick
                  </a>
                </sui.Grid.Row>
                <sui.Segment>
                  <sui.Label ribbon style={ribbonStyle}>
                    Dokumente
                  </sui.Label>
                  <br />
                  <br />
                  <sui.Grid columns={mailDocumentColumns} divided>
                    {
                      mailDocuments.map(d =>
                        <sui.Grid.Column stretched style={mailDocumentStyle}>
                          <a target="_blank" rel="noopener noreferrer" href={d[0]}><sui.Icon name='download' color='black' />{d[1]}</a>
                        </sui.Grid.Column>
                      )
                    }
                  </sui.Grid>
                  <br />
                </sui.Segment>
                <br />
              </sui.Accordion.Content>
            </sui.Accordion>
          </SlimUI>
        </div>
        <br />
        <br />
      </div>
    );
  }
}

export default windowSize(AntragPage);
