import React from 'react';
import * as sui from 'semantic-ui-react';

import windowSize from 'react-window-size';
import Colors from '../constants/colors';

class WideUI extends React.Component {
    render() {
        const wideStyle = {
            width: this.props.width || '85%',
            fontSize: this.props.fontSize || '20px',
            padding: this.props.padding || '30px',
            lineHeight: this.props.lineHeight || '2.0',
            color: Colors.onBackground,
        };

        return (
            <sui.Container style={wideStyle}>
                {this.props.children}
            </sui.Container>
        );
    }
}

export default windowSize(WideUI);
