import React from 'react';
import * as sui from 'semantic-ui-react';
import './projectItem.css';

// addons
import 'firebase/database';

// elements
import WideUI from '../elements/wideUI';

class ProjectItemEditor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            accordionOpen: false,
            currentData: {},
            contentStr: '',
            empty: false,
        };
        this.onAccordionClick = () => {
            this.setState({'accordionOpen': !this.state['accordionOpen']});
        };
        this.dbrSetup = false;
    }

    render() {
        const {item, itemSelected, dragHandleProps, anySelected} = this.props;

        this.dbr = item.dbr;
        if (this.dbr !== null && !this.dbrSetup) {
            console.log(`hello '${this.dbr.path.toString()}'`);
            this.dbr.on('value', s => {
                if (s.val() == null) {
                    console.log(`couldn't get data for db ref '${this.dbr.path.toString()}'`);
                    this.setState({empty: true});
                    return;
                }
                else {
                    console.log(`successfully got ref data for '${this.dbr.path.toString()}'`);
                }
                this.setState({currentData: s.val(), contentStr: s.val().content.join(' ')});
            });
            this.onFieldChange = (k, v) => {
                if (this.props.item.loggedIn) {
                    this.setState({currentData: {...this.state.currentData, [k]: v.target.value}}, () => {
                        this.dbr.set(this.state.currentData);
                    });
                }
            };
            this.onContentChange = v => {
                if (this.props.item.loggedIn) {
                    const strVal = v.target.value;
                    this.setState({contentStr: strVal, currentData: {...this.state.currentData, content: strVal.split(' ')}}, () => {
                        this.dbr.set(this.state.currentData);
                    });
                }
            };
            this.dbrSetup = true;
        }

        const rootStyle = {
            backgroundImage: (this.state.accordionOpen ? 'linear-gradient(#000000E6, #000000C4, #000000E6)' : 'linear-gradient(#000000E0, #00000040, #000000E0)') + ', url("' + this.state.currentData.bg + '")',
            backgroundColor: '#000000',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            transform: `scale(${itemSelected * 0.05 + 1})`,
        };
        const titleStyle = {
            color: '#ffffff',
            letterSpacing: '2px',
        };
        const fieldStyle = {
        };
        const textAreaStyle = {
            ...fieldStyle,
            whiteSpace: 'pre-wrap',
            minWidth: '100%',
            maxWidth: '100%',
            minHeight: '30px',
            height: '30vh',
        };
        const dragHandleStyle = {
            width: '20px',
            height: '30px',
            position: 'absolute',
            left: '20px',
            top: '40px',
            backgroundImage: 'url("/images/icon/grabber.png")',
        };

        return (this.state.empty ? <></> :
            <div style={rootStyle}>
                <WideUI>
                    <sui.Accordion>
                        <sui.Accordion.Title active={this.state.accordionOpen && anySelected === 0} onClick={() => {
                            if (!itemSelected) {
                                this.onAccordionClick();
                            }
                        }}>
                            <sui.Header style={titleStyle}>
                                <div style={dragHandleStyle} {...dragHandleProps} />
                                <sui.Icon name='dropdown' />
                                {this.state.currentData.title}
                            </sui.Header>
                        </sui.Accordion.Title>
                        <sui.Accordion.Content active={this.state.accordionOpen && anySelected === 0}>
                            <sui.Input label='Titel' fluid value={this.state.currentData.title} onChange={v => this.onFieldChange('title', v)} style={fieldStyle} type='text' />
                            <sui.Input label='Hintergrund' fluid value={this.state.currentData.bg} onChange={v => this.onFieldChange('bg', v)} style={fieldStyle} type='text' />
                            <sui.Input label='Gallerie' fluid value={this.state.contentStr} onChange={this.onContentChange} style={fieldStyle} type='text' />
                            <sui.Input label='Info' fluid value={this.state.currentData.top} onChange={v => this.onFieldChange('top', v)} style={fieldStyle} type='text' />
                            <sui.TextArea label='Beschreibung' fluid value={this.state.currentData.desc} onChange={v => this.onFieldChange('desc', v)} style={textAreaStyle} type='text' />
                            <sui.Input label='Produktionszeitraum' fluid value={this.state.currentData.timespan} onChange={v => this.onFieldChange('timespan', v)} style={fieldStyle} type='text' />
                            <sui.Input label='Produktion' fluid value={this.state.currentData.production} onChange={v => this.onFieldChange('production', v)} style={fieldStyle} type='text' />
                            <sui.Input label='Regie' fluid value={this.state.currentData.director} onChange={v => this.onFieldChange('director', v)} style={fieldStyle} type='text' />
                            <sui.Input label='Drehbuch' fluid value={this.state.currentData.script} onChange={v => this.onFieldChange('script', v)} style={fieldStyle} type='text' />
                        </sui.Accordion.Content>
                    </sui.Accordion>
                </WideUI>
            </div>
        );
    }
}

export default ProjectItemEditor;
