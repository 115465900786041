import React from 'react';
import * as sui from 'semantic-ui-react';

// constants
import Colors from '../constants/colors';
import Network from '../constants/network';

// addons
import windowSize from 'react-window-size';
import { isMobile } from 'react-device-detect';

// elements
import { MenuBarMargin } from '../elements/menuBar';
import WideUI from '../elements/wideUI';
import ScrollFade from '../elements/scrollFade';
import Checkbox from '../elements/checkbox';
import FormFullValidation from '../elements/formFullValidation';
import { Link } from 'react-router-dom';

class ZielePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { accordionOpen: false, fileData: '', loading: false };
    this.onFileChange = (e) => {
      if (e.target.files && e.target.files[0]) {
        if (!e.target.files[0].name.endsWith('.pdf')) {
          this.setState({ dimmerState: 2, fileData: '' });
        }
        else {
          var reader = new FileReader();
          reader.onloadend = (e2) => {
            this.setState({
              fileData: e2.target.result
            });
          }
          reader.readAsArrayBuffer(e.target.files[0]);
        }
      }
    };
    this.onFileSend = _ => {
      if (!this.state.datenschutz) {
        this.setState({ datenschutzComplain: true });
      }
      else {
        this.setState({ loading: true, datenschutzComplain: false });
        const requestOptions = {
          method: 'POST',
          body: this.state.fileData,
        };
        return fetch(Network.backendHost + 'kartei', requestOptions).then(res => {
          this.setState({ loading: false, dimmerState: res.status === 200 ? 1 : 3 });
        }).catch(error => {
          console.error(error);
          this.setState({ loading: false, dimmerState: 3 });
        });
      }
    };
  }

  render() {
    const fileSupported = window.FileReader ? true : false;
    const scrollThreshold = 0.15;
    const mailDocuments = [
      ['/FETZWERK_kartei.pdf', 'Karteiantrag'],
    ];
    const mailDocumentColumns = this.props.windowWidth / mailDocuments.length > 220 ? mailDocuments.length : 1;

    // styles
    const backgroundStyle = {
      backgroundColor: Colors.background,
      minHeight: '100vh',
      padding: '20px 0',
    };
    const headerStyle = {
      color: Colors.onBackground,
      fontSize: '5vh',
      marginBottom: '30px',
    };
    const subHeaderStyle = {
      color: Colors.onBackground,
      fontSize: '30px',
      margin: '20px 0',
    };
    const textStyle = {
      fontSize: isMobile ? '19px' : '23px',
      lineHeight: isMobile ? '1.4' : '1.7',
      fontFamily: '\'Montserrat\', sans-serif',
      textAlign: isMobile ? '' : 'justify',
      marginBottom: '8px',
    };
    const accordionBGStyle = {
      backgroundColor: '#000000',
      width: '100%',
      padding: '20px 10vw 25px 10vw',
    };
    const accordionStyle = {
      color: '#ffffff',
      fontSize: '25px',
    };
    const accordionContentStyle = {
      fontSize: '20px',
      lineHeight: '1.8',
      textAlign: isMobile ? '' : 'justify',
      color: '#ffffff',
      marginBottom: '25px',
    };
    const ribbonStyle = {
      backgroundColor: Colors.primary,
      color: Colors.onPrimary,
    };
    const mailDocumentStyle = {
      textAlign: mailDocumentColumns === 1 ? 'left' : 'center',
      fontSize: '16px'
    };
    const uploadHeaderStyle = {
      color: '#ffffff',
    };
    const uploadStyle = {
      fontSize: 'min(4vw, 18px)',
    };
    const checkboxStyle = {
      color: '#ffffff',
      fontSize: '18px'
    };
    const submitBtnStyle = {
      display: 'block',
      margin: 'auto',
      marginTop: '16px',
      fontSize: '16px',
    };
    const dimmerCloseStyle = {
      marginTop: '20px',
    };
    const bannerStyle = {
      backgroundColor: '#000000',
      color: Colors.aHover,
      fontSize: '20px',
      textAlign: 'center',
      padding: '20px',
      width: '100vw',
      marginBottom: '10vh',
    };
    const btnStyle = {
      marginLeft: '3vw',
    };

    return (
      <div style={backgroundStyle}>
        <MenuBarMargin />
        <WideUI>
          <sui.Header as='h1' style={headerStyle}>Unsere Ziele</sui.Header>
          <ScrollFade threshold={scrollThreshold}>
            <div style={textStyle}>
              Die Idee einen eigenen Verein zu gründen entstand, wie könnte es anders sein, bei einem Filmdreh im Sommer 2019. In einem Gespräch über Schwierigkeiten, die beim Initiieren von Jugendfilmprojekten auftauchen, wurde klar, dass eine offizielle gemeinnützige Körperschaft beim Realisieren von nicht gewinnorientierten Filmproduktionen vieles erleichtern könnte. Als minderjährige Person ein Projekt durch die Vorproduktion zu bringen, also Sponsoren zu finden, die einer Privatperson Geld anvertrauen, einen Drehzeitraum festzulegen, eine Crew aus kompetenten Filmemachern zu besetzten, Drehorte zu organisieren und generell mit einem Projekt ernst genommen zu werden, ist unglaublich schwer. Besonders, wenn man bisher keine großen Erfolge vorweisen kann. Das ist sehr schade, weil dadurch viele Projekte gar nicht erst zustande kommen und viele junge Menschen ihre mitunter sehr guten Ideen schlichtweg nicht umsetzen können. Genau das soll der Verein ändern indem er allen jungen Künstlern, die ein interessantes Konzept haben, Rückenwind gibt und sie in verschiedenen Bereichen ihrer Projekte unterstützt.
            </div>
          </ScrollFade>
          <ScrollFade threshold={scrollThreshold}>
            <sui.Header as='h1' style={subHeaderStyle}>Fetzwerk-Studio</sui.Header>
            <div style={textStyle}>
              Das Fetzwerk-Studio ist ein Online-Netzwerk, bestehend aus vielen motivierten jungen Filmemachern, die in unterschiedlichen Bereichen der Filmproduktion aktiv sind, Lust haben an neuen Projekten mitzuwirken und gerne andere Filmemacher kennenlernen wollen. Wenn es darum geht für ein eigenes Projekt Beteiligte zu suchen oder du selbst auf der Suche nach neuen Projekten bist, an denen du dich beteiligen kannst, dann ist das Studio eine große Hilfe. Du knüpfst neue Kontakte und kannst dir ein Netzwerk aufbauen. Zudem bietet das Studio die Möglichkeit sich auszutauschen. Seien es Fragen, Anregungen oder die gemeinsame Weiterentwicklung von Projektideen, das Studio ist der ideale Raum, um aus dem Netzwerk von jungen Filmemachern zu profitieren. Wir haben das Ziel mit dem Studio jedem die Möglichkeit zu geben, sich stets weiterzuentwickeln, immer mehr dazu zu lernen und vielfältige Erfahrungen sammeln zu können. Melde dich einfach an und werde ein Teil.
            </div>
            <a href='https://studio.fetzwerk.net/'>
              <sui.Button className='primaryColor'>
                Zum Fetzwerk-Studio
                <sui.Icon name="arrow right" />
              </sui.Button>
            </a>
          </ScrollFade>
        </WideUI>
        {/*
        <div style={accordionBGStyle}>
          <sui.Accordion>
            <sui.Accordion.Title style={accordionStyle} active={this.state.accordionOpen} onClick={() => this.setState({accordionOpen: !this.state.accordionOpen})}>
              <sui.Icon name='dropdown' />
              <b>Antrag für das Fetzwerk-Studio</b>
            </sui.Accordion.Title>
            <sui.Accordion.Content active={this.state.accordionOpen}>
              <div style={accordionContentStyle}>
                Den Antrag, um in die Fetzwerk-Kartei aufgenommen zu werden, können Sie einfach ausfüllen und hier hochladen. Alternativ senden Sie diesen entweder per Mail an <a href='mailto: l.seidler@fetzwerk.net'>l.seidler@fetzwerk.net</a> oder per Post an <a target="_blank" rel="noopener noreferrer" href='https://goo.gl/maps/Dp8foybdsfqp55T58'>Fetzwerk-Filmförderverein e.V., Im Hegen 27, 21335 Lüneburg</a>.
                <sui.Segment>
                  <sui.Label ribbon style={ribbonStyle}>
                    Dokumente
                  </sui.Label>
                  <br />
                  <br />
                  <sui.Grid columns={mailDocumentColumns} divided>
                  {
                    mailDocuments.map(d =>
                      <sui.Grid.Column stretched style={mailDocumentStyle}>
                        <a target="_blank" rel="noopener noreferrer" href={d[0]}><sui.Icon name='download' color='black' />{d[1]}</a>
                      </sui.Grid.Column>
                    )
                  }
                  </sui.Grid>
                  <br />
                </sui.Segment>
                <sui.Header style={uploadHeaderStyle}>
                  Upload
                </sui.Header>
                <input type='file' accept='' onChange={this.onFileChange} id='pdf' name='pdf' style={uploadStyle} />
              </div>
              <FormFullValidation visible={this.state.datenschutzComplain} text='Sie müssen die Datenschutzerklärung akzeptieren'>
                <sui.Form.Field style={checkboxStyle}>
                  <Checkbox checkMarginLeft='0.5px' checkMarginTop='0' name='datenschutz' value={this.state.datenschutz} onChange={(_, {name, value}) => this.setState({datenschutz: value, datenschutzComplain: false})}>
                    Sie haben die <a target="_blank" rel="noopener noreferrer" href='/datenschutz'>Datenschutzerklärung</a> gelesen und akzeptieren, dass wir Daten speichern.
                  </Checkbox>
                </sui.Form.Field>
              </FormFullValidation>
              <sui.Button className='primaryColor' style={submitBtnStyle} onClick={this.onFileSend} disabled={!fileSupported || !this.state.fileData || this.state.loading} loading={this.state.loading}>
                Senden
              </sui.Button>
            </sui.Accordion.Content>
          </sui.Accordion>
        </div>
        */}
        <WideUI>
          <ScrollFade threshold={scrollThreshold}>
            <sui.Header as='h1' style={subHeaderStyle}>Unterstützung von Projekten</sui.Header>
            <div style={textStyle}>
              Häufig tauchen die größten Schwierigkeiten bei den ersten eigenen Projekten bereits in der Finanzierungsphase auf. Aufgrund der Masse an Projektinitiatoren, die Gelder für irgendetwas suchen, ist es schwer, herauszustechen. Unternehmen oder private Geldgeber von dem eigenen Projekt zu überzeugen und ihnen klar zu machen, warum sie nun gerade in das eigene Projekt investieren sollten, kann dabei häufig ein Kraftakt sein. Hierbei kann häufig allein die Option, eine Spendenbescheinigung auszustellen und der Vorteil, eine offizielle gemeinnützige Körperschaft hinter sich zu haben, Türen öffnen. Genau das bieten  wir mit unserem Verein allen Mitgliedern für ihre eigenen nicht gewinnorientierten Projekte an. Sie haben die Möglichkeit sämtliche finanziellen Belange über den Verein abzuwickeln und so wesentlich weniger Probleme bei der Finanzierung zu haben. Darüber hinaus steht der Verein mit Rat und Tat bei jeglichen Schwierigkeiten und Fragen zur Seite und unterstützt Projekte bei Bedarf in allen Bereichen. So soll es jungen Filmemachern leichter fallen, ihre eigenen Filmprojekte zu realisieren.
            </div>
          </ScrollFade>
        </WideUI>
        <sui.Header style={bannerStyle}>
          {/* Wenn du Mitglied im Verein werden willst, dann melde dich hier an. */}
          Wenn du Teil des Fetzwerks werden willst, dann werde Mitglied im Verein.
          <Link to='/antragSelect'><sui.Button style={btnStyle} className='primaryColor'>Mitglied werden</sui.Button></Link>
        </sui.Header>
        <sui.Dimmer active={this.state.dimmerState > 0} onClickOutside={() => { }} page>
          <sui.Header as='h2' icon inverted>
            {this.state.dimmerState === 1 ?
              <>
                <sui.Icon name='check' />
                Gesendet
                <sui.Header.Subheader>{this.props.successText || 'Ihre Anfrage wurde erfolgreich verschickt!'}</sui.Header.Subheader>
              </> :
              <>
                <sui.Icon name='x' />
                Error
                {this.state.dimmerState === 2 ?
                  <sui.Header.Subheader>Ein Fehler ist aufgetreten, überprüfen Sie Ihre Eingabe (Es können nur .pdf-Dateien verschickt werden).</sui.Header.Subheader> :
                  <sui.Header.Subheader>Ihre Anfrage konnte nicht verschickt werden, versuchen Sie es später erneut (Es können nur .pdf-Dateien verschickt werden).</sui.Header.Subheader>
                }
              </>
            }
            <sui.Button style={dimmerCloseStyle} className='primaryColor' onClick={() => this.setState({ dimmerState: 0 })}>Okay</sui.Button>
          </sui.Header>
        </sui.Dimmer>
      </div>
    );
  }
}

export default windowSize(ZielePage);
