import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

// constants
import FirebaseConstants from './constants/firebase'

// pages
import NotFoundPage from './pages/notfound.js';
//import ConstructionPage from './pages/construction.js';
import HomePage from './pages/home.js';
import AdminPage from './pages/admin.js';
import ProjectsPage from './pages/projects.js';
import KontaktPage from './pages/kontakt.js';
import AntragSelectPage from './pages/antragSelect.js';
import AntragPage from './pages/antrag.js';
import ImpressumPage from './pages/impressum.js';
import DatenschutzPage from './pages/datenschutz.js';
import ZielePage from './pages/ziele.js';
import MailPage from './pages/mail.js';
import SpendenPage from './pages/spenden.js';

// custom elements
import ScrollToTop from './elements/scrollToTop.js';
import MenuBar from './elements/menuBar.js';
import ScrollTopBtn from './elements/scrollTopBtn.js';
import Footer from './elements/footer.js';

import * as serviceWorker from './serviceWorker';
import 'semantic-ui-less/semantic.less'
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import Helmet from 'react-helmet';
import HttpsRedirect from 'react-https-redirect';
import firebase from 'firebase/app';
import 'firebase/auth';

const pages = [
  {key: 'admin',        path: '/admin',        title: 'Admin | FETZWERK',           element: AdminPage},
  {key: 'projekte',     path: '/projekte',     title: 'Projekte | FETZWERK',        element: ProjectsPage},
  {key: 'kontakt',      path: '/kontakt',      title: 'Kontakt | FETZWERK',         element: KontaktPage},
  {key: 'antragSelect', path: '/antragSelect', title: 'Mitglied werden | FETZWERK', element: AntragSelectPage},
  {key: 'antrag',       path: '/antrag',       title: 'Mitglied werden | FETZWERK', element: AntragPage},
  {key: 'impressum',    path: '/impressum',    title: 'Impressum | FETZWERK',       element: ImpressumPage},
  {key: 'datenschutz',  path: '/datenschutz',  title: 'Datenschutz | FETZWERK',     element: DatenschutzPage},
  {key: 'ziele',        path: '/ziele',        title: 'Unsere Ziele | FETZWERK',    element: ZielePage},
  {key: 'mail',         path: '/mail',         title: 'Mail-Ansicht | FETZWERK',    element: MailPage},
  {key: 'spenden',      path: '/spenden',      title: 'Spenden | FETZWERK',         element: SpendenPage},
  {key: 'home',         path: '/',             title: 'Home | FETZWERK',            element: HomePage, exact: true},
  {key: 'notFound',     path: '',              title: '404 | FETZWERK',             element: NotFoundPage},
];

const barRef = React.createRef();
const scrollRef = React.createRef();
const pageRef = React.createRef();

firebase.initializeApp(FirebaseConstants.config);
firebase.auth().signOut();
firebase.auth().setPersistence(firebase.auth.Auth.Persistence.NONE);

/*
var colorIndex = 0;
const colors = ['#FF7F00', '#d18a54', '#e88031', '#AB0C34', '#eb4034', '#607d61', '#631d1b', '#89a5ab', '#5580e6', '#242424', '#5c5c5c', '#8f8f8f', '#bfbfbf', '#ffffff'];

function colorhandler() {
  colorIndex = (colorIndex + 1) % colors.length;
  Colors.primary = colors[colorIndex];
  barRef.current.forceUpdate();
  scrollRef.current.forceUpdate();
  pageRef.current.forceUpdate();
}
*/

ReactDOM.render(
  <React.StrictMode>
    <HttpsRedirect>
      <Router>
        <ScrollToTop />
        <Switch>
          {pages.map((p) =>
            <Route lang="de" key={p.key} path={p.path} exact={p.exact}>
              <Helmet>
                <title>{p.title}</title>
              </Helmet>
              <MenuBar ref={barRef} /*colorhandler={colorhandler}*/ />
              <ScrollTopBtn ref={scrollRef} />
              {<p.element ref={pageRef} />}
              <Footer />
            </Route>
          )}
        </Switch>
      </Router>
    </HttpsRedirect>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
