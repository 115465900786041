import React from 'react';
import InView from 'react-intersection-observer';

class ScrollFade extends React.Component {
    constructor(props) {
        super(props);
        this.state = {visible: false};
    }

    render() {
        // styles
        const rootStyle = {
            transition: 'opacity ' + (this.props.transition || '1s'),
            opacity: this.state.visible ? 1 : 0,
        };

        return (
            <InView threshold={this.props.threshold || 0.2} onChange={(inView, _) => this.setState({visible: inView})}>
                <div style={rootStyle}>
                    {this.props.children}
                </div>
            </InView>
        );
    }
}

export default ScrollFade;
