import React from 'react';
import * as sui from 'semantic-ui-react';
import './menuBar.css';
import { isBrowser } from 'react-device-detect';
import windowSize from 'react-window-size';
import { Link } from 'react-router-dom';

class MenuBar extends React.Component {
    static windowWidth = 580; // defaults to widescreen (single row)
    static windowScroll = 0;

    static isSmall() {
        return MenuBar.windowWidth < 580;
    }

    static isMinimized() {
        return MenuBar.windowScroll <= 20;
    }

    static getHeight() {
        return MenuBar.isSmall() ? 50 : 74;
    }

    constructor(props) {
        super(props);
        this.scrollHandler = () => {
            const pre = MenuBar.isMinimized();
            MenuBar.windowScroll = window.pageYOffset;
            if (MenuBar.isMinimized() !== pre) {
                this.setState({state: this.state});
            }
        };
    }

    componentDidMount() {
        window.addEventListener('scroll', this.scrollHandler);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.scrollHandler);
    }

    render() {
        MenuBar.windowWidth = this.props.windowWidth;
        const path = window.location.pathname;

        // styles
        const rootStyle = {
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            zIndex: 1000,
        };
        const mainStyle = {
            width: '100%',
            textAlign: 'center',
            paddingTop: '5px',
            paddingBottom: '5px',
            transition: 'background-color 1s ease',
            backgroundColor: MenuBar.isMinimized() ? '#00000000' : '#000000FF',
        };
        const centerStyle = MenuBar.isSmall() ? {
            display: 'inline-block',
            margin: 'auto',
        } : {};
        const hideOnSmall = {
            display: MenuBar.isSmall() ? 'none' : '',
        };
        const menuStyle = {
            ...hideOnSmall,
            backgroundColor: '#00000000',
            marginBottom: 5,
        };
        const menuImgStyle = {
            ...hideOnSmall,
            height: '37px',
            margin: 'auto 0',
            paddingTop: '5px',
        };
        const navMenuBasicStyle = {
        };
        const navMenuStyle = MenuBar.isSmall() ? {
            ...navMenuBasicStyle,
            fontSize: Math.min(this.props.windowWidth * 0.035, 14),
        } : {
            ...navMenuBasicStyle,
            position: 'absolute',
            top: 15,
            right: 20,
        };

        return (
            <div style={rootStyle}>
                <div style={mainStyle} className='mainStyle'>
                    <div style={centerStyle}>
                        <sui.Menu secondary style={menuStyle}>
                            <sui.Menu.Item className="noBG">
                                <Link to="/"><sui.Image src="/images/logo/logo_fetzwerk.png" style={menuImgStyle} /></Link>
                            </sui.Menu.Item>
                            <sui.Menu.Item className="noBG">
                                <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/dasfilmnetzwerk/"><sui.Image src="/images/logo/logo_instagram.png" style={menuImgStyle} /></a>
                            </sui.Menu.Item>
                        </sui.Menu>
                    </div>
                    <div style={centerStyle}>
                        <sui.Menu secondary style={navMenuStyle}>
                            {/*
                            <sui.Menu.Item className="noBG">
                                <sui.Button circular icon primary onClick={this.props.colorhandler} style={{backgroundColor: Colors.primary}} >
                                    <sui.Icon name="adjust"/>
                                </sui.Button>
                            </sui.Menu.Item>
                            */}
                            <sui.Menu.Item as={Link} to="/" className={path === "/" ? "navMenuActive" : ""}>HOME</sui.Menu.Item>
                            <sui.Dropdown text='VEREIN' item simple={isBrowser} options={[
                                { value: 0, text: 'Ziele', as: Link, to: '/ziele' },
                                { value: 1, text: 'Projekte', as: Link, to: '/projekte' },
                                //{ value: 2, text: 'Spenden', as: Link, to: '/spenden' },
                            ]} />
                            <sui.Menu.Item as={Link} to="/kontakt" className={path === "/kontakt" ? "navMenuActive" : ""}>KONTAKT</sui.Menu.Item>
                        </sui.Menu>
                    </div>
                </div>
            </div>
        );
    }
}

export const MenuBarIsSmall = MenuBar.isSmall;
export const MenuBarIsMinimized = MenuBar.isMinimized;
export const MenuBarHeight = MenuBar.getHeight;

export default windowSize(MenuBar);
export class MenuBarMargin extends React.Component {
    render() {
        const marginStyle = {
            paddingBottom: MenuBar.getHeight(),
        };
        return (
            <div style={marginStyle}/>
        );
    }
}
