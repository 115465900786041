import React from 'react';
import * as sui from 'semantic-ui-react';

// addons
import windowSize from 'react-window-size';
//import hookcord from 'hookcord';

// elements
import {MenuBarMargin} from '../elements/menuBar.js';
import Colors from '../constants/colors';
import SlimUI from '../elements/slimUI';
import { Link } from 'react-router-dom';

class AntragSelectPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const bigIcons = this.props.windowWidth > 440;
    const iconSize = bigIcons ? "massive" : "huge";

    // styles
    const mainStyle = {
      backgroundColor: Colors.background,
      minHeight: '100vh',
    };
    const onBG = {
      color: Colors.onBackground,
    };
    const headerStyle = {
      ...onBG,
      textAlign: 'center',
      marginBottom: '30px',
    };
    const gridStyle = {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
    };
    const btnStyle = {
        width: 'min(300px, 40vw)',
        height: bigIcons ? '190px' : '150px',
        margin: '0 2vw',
    };
    const iconStyle = {
        height: bigIcons ? '130px' : '70px',
        margin: 'auto',
    };

    return (
      <div style={mainStyle}>
        <MenuBarMargin />
        <SlimUI>
            <sui.Header style={headerStyle}>Mitglied werden</sui.Header>
            <div style={gridStyle}>
              <Link to='/antrag?type=private'>
                <sui.Button style={btnStyle} className='primaryColor'>
                    <sui.Icon name="user" style={iconStyle} size={iconSize}></sui.Icon>
                    <br />
                    Ich möchte persönlich Mitglied werden.
                </sui.Button>
              </Link>
              <Link to='/antrag?type=business'>
                <sui.Button style={btnStyle} className='primaryColor'>
                    <sui.Icon name="building outline" style={iconStyle} size={iconSize}></sui.Icon>
                    <br />
                    Ich möchte mit unserer Firma Mitglied werden.
                </sui.Button>
              </Link>
            </div>
        </SlimUI>
      </div>
    );
  }
}

export default windowSize(AntragSelectPage);
