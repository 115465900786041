import React from 'react';
import * as sui from 'semantic-ui-react';

// constants
import Colors from '../constants/colors';

// addons
import windowSize from 'react-window-size';

// elements
import { MenuBarMargin } from '../elements/menuBar';
import WideUI from '../elements/wideUI';
import { isMobile } from 'react-device-detect';

class MailPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    const s = new URLSearchParams(window.location.search);
    const mvt = [];
    var reload = false;
    for (var i of s) {
      if (i[0].startsWith('mvt_')) {
        mvt.push([i[0].substr(4), i[1]]);
      }
      else {
        localStorage.setItem('mailview_' + i[0], i[1]);
      }
      reload = true;
    }
    if (reload) {
      localStorage.setItem('mailview_mvt', JSON.stringify({v: mvt}));
      window.location.href = '/mail';
    }
  }

  render() {
    // styles
    const backgroundStyle = {
      backgroundColor: Colors.background,
      minHeight: '100vh',
      padding: '20px 0',
    };
    const headerStyle = {
      color: Colors.onBackground,
      fontSize: '5vh',
      marginBottom: '30px',
    };
    const subHeaderStyle = {
      color: Colors.onBackground,
      fontSize: '30px',
      margin: '20px 0',
    };
    const textStyle = {
      fontSize: isMobile ? '15px' : '23px',
      lineHeight: '1.8',
      fontFamily: '\'Montserrat\', sans-serif',
      textAlign: 'justify',
      whiteSpace: 'pre-line',
    };
    const tableStyle = {
      borderCollapse: 'collapse',
      textAlign: 'left',
      margin: 'auto',
      maxWidth: '80vw',
    };
    const itemStyle = {
      padding: '10px',
      maxWidth: '40vw',
      wordBreak: 'break-word',
    };

    return (
      <div style={backgroundStyle}>
        <MenuBarMargin />
        <WideUI>
          <sui.Header as='h1' style={headerStyle}>Mail-Ansicht</sui.Header>
          <sui.Header as='h1' style={subHeaderStyle}>{localStorage.getItem('mailview_title') || ''}</sui.Header>
            <div style={textStyle}>
              {localStorage.getItem('mailview_message') || ''}<br />
              <table style={tableStyle}>
                {JSON.parse(localStorage.getItem('mailview_mvt') || '{"v": []}')['v'].map((v, i) =>
                  <tr style={{backgroundColor: i % 2 === 1 ? '' : '#101010'}}>
                    <th style={itemStyle}>{v[0]}</th>
                    <td style={itemStyle}>{v[1]}</td>
                  </tr>
                )}<br />
              </table>
              Ihr FETZWERK Team
            </div>
        </WideUI>
      </div>
    );
  }
}

export default windowSize(MailPage);
