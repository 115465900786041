import React from 'react';
import * as sui from 'semantic-ui-react';

// addons
import windowSize from 'react-window-size';

class NotFoundPage extends React.Component {
  render() {
    const landscape = this.props.windowWidth > this.props.windowHeight;

    // styles
    const backgroundStyle = {
      backgroundImage: `linear-gradient(#711db570, #b771e3ff), url(${"images/bg/background_notfound.jpg"})`,
      backgroundSize: 'repeat',
      backgroundAttachment: 'fixed',
      minHeight: '100vh',
    };
    const headerStyle = {
      position: 'absolute',
      top: landscape ? 'calc(50vh - 5vw)' : 'calc(50vh - 20vw)',
    };
    const logoStyle = {
      width: landscape ? '50%' : '80%',
      height: 'auto',
    }
    const subHeaderStyle = {
      textAlign: 'center',
      color: 'white',
      marginTop: 5,
      fontFamily: 'Courier New',
      fontSize: 'min(8vh, 4vw)',
      fontWeight: 100,
    };

    return (
      <div>
        <div style={backgroundStyle}>
          <div style={headerStyle}>
            <sui.Image centered src='/images/logo/logo_404.png' style={logoStyle} size='massive' className='noSelect'></sui.Image>
            <sui.Header as="h1" style={subHeaderStyle} className="noSelect">wo wolltest du denn hin?</sui.Header>
          </div>
        </div>
      </div>
    );
  }
}

export default windowSize(NotFoundPage);
