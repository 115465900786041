import React from 'react';
import * as sui from 'semantic-ui-react';

import Colors from '../constants/colors';

class FormFullValidation extends React.Component {
    render() {
        if (this.props.text) {
            this.text = this.props.text;
        }
        else {
            this.text = 'Dieses Feld muss ausgefüllt werden';
        }

        const labelStyle = {
            color: Colors.onError,
            backgroundColor: Colors.error,
            marginTop: '10px',
        };

        return (
            <sui.Form.Field>
                {this.props.children}
                {this.props.visible
                    ? <sui.Label pointing style={labelStyle}>{this.text}</sui.Label>
                    : <div></div>
                }
            </sui.Form.Field>
        );
    }
}

export default FormFullValidation;
