import React from 'react';
import * as sui from 'semantic-ui-react';

// constants
import Colors from '../constants/colors';

// elements
import { MenuBarMargin } from '../elements/menuBar';
import ProjectListDB from '../elements/projectListDB';
import ProjectEditorList from '../elements/projectEditorList';

// addons
import windowSize from 'react-window-size';

class AdminPage extends React.Component {
    render() {
        // styles
        const backgroundStyle = {
            backgroundImage: `linear-gradient(#101010F0, #050505FA), url(${"images/bg/background_construction.jpg"})`,
            backgroundSize: 'repeat',
            backgroundAttachment: 'fixed',
            minHeight: '100vh',
            paddingBottom: '50px',
        };
        const headerStyle = {
            marginLeft: '5vh',
            fontSize: '6vh',
            color: Colors.onBackground,
        };
        const subHeaderStyle = {
            marginLeft: '5vh',
            fontSize: '3vh',
            color: Colors.onBackground,
        };

        return (
            <div style={backgroundStyle}>
                <MenuBarMargin />
                <sui.Header style={headerStyle}>
                    Admin
                </sui.Header>
                <sui.Header style={subHeaderStyle}>
                    Editor
                </sui.Header>
                <ProjectEditorList />
                <sui.Header style={subHeaderStyle}>
                    Vorschau
                </sui.Header>
                <ProjectListDB />
            </div>
        );
    }
}

export default windowSize(AdminPage);
